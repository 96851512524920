import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet-async';
import { isBrowser, isMobile, mobileModel } from "react-device-detect";
import { withFirebase } from '../Firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Report from '../Report/Report';
import ProfilePlaceholder from '../../constants/ProfilePlaceholder.png';

import './RequestHandeler.scss';

import * as ROUTES from '../../constants/routes';

class RequestHandeler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      match: null,
      author: null,
      requests: [],
      index: 0,
      loading: true
    }
  }

  acceptRequest = () =>
    this.props.firebase.acceptMatchRequest(this.state.match, this.state.requests[this.state.index])
      .then(writtenRequest => {
        this.props.acceptedPlayerToParent(writtenRequest);
        if (this.state.index !== 0)
          this.setState(prevState => ({ index: prevState.index - 1 }))
      })

  denyRequest = () => 
    this.props.firebase.denyMatchRequest(this.state.match.id, this.state.requests[this.state.index])
      .then((deniedRequest) => {
        this.props.deniedPlayerToParent(deniedRequest);
        if (this.state.index !== 0)
          this.setState(prevState => ({ index: prevState.index - 1 }))
      })
  

  tabRequests = (diff) => {
    var newIndex = this.state.index + diff;

    if (newIndex > (this.state.requests.length - 1))
      newIndex = 0;
    else if (newIndex < 0)
      newIndex = this.state.requests.length - 1;

    this.setState({ index: newIndex })
  }

  getRequests = (matchId) => {
    this.props.firebase.getMatchRequests(matchId)
      .then(requests => {
        this.setState({ requests, index: 0, loading: false })
      })
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ match: this.props.location.state, requests: this.props.requests, author: this.props.location.state.author })
    }
    else {
      if (this.props.match.params.matchId)
        this.props.firebase.getMatchById(this.props.match.params.matchId)
          .then(match => {
            this.setState({ match, author: match.author })
            this.getRequests(this.props.match.params.matchId)
          })
      else
        this.props.history.replace(ROUTES.CREATE)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.state && this.props.location.state !== prevProps.location.state && matchPath(this.props.location.pathname, ROUTES.REQUESTS))
      this.setState({ match: this.props.location.state, author: this.props.location.state.author, loading: true })
    
    if (this.props.requests && this.props.requests !== prevState.requests)
      this.setState({ requests: this.props.requests, loading: false })
  }

  dismount() {
    if (isMobile) this.props.history.goBack()
    else this.props.history.replace(ROUTES.CREATE)
  }

  render() {
    let currentRequest, currentRequestee
    if (!this.state.loading) {
      currentRequest = this.state.requests[this.state.index];
      currentRequestee = currentRequest && currentRequest.requestee ? currentRequest.requestee : {};
    }

    return (
      <Col md={isBrowser ? 4 : 12} sm={12} style={this.props.transition}
        className={`requestPage justify-content-center ${isMobile ? 'mobileOverlay' : 'browserSidebar'}
                      ${mobileModel} w${window.screen.width}h${window.screen.height}`}>
        <Helmet>
          <title>Spelarpoolen | Ansökningar</title>
        </Helmet>

        {!this.state.loading && currentRequest
          ? <Container fluid>
              <Row className={`requestTabBar ${this.state.requests.length < 2 ? 'notScrollable' : 'scrollable'}`}>
                <Col className="d-flex justify-content-start" xs={1} onClick={() => this.tabRequests(1)}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Col>
                <Col className="d-flex justify-content-center" xs={10}>
                  <p style={{ padding: '0px' }}>Ansökan {this.state.index + 1} av {this.state.requests.length}</p>
                </Col>
                <Col className="d-flex justify-content-end" xs={1} onClick={() => this.tabRequests(1)} >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Col>
              </Row>

              <div className="requestContent">
                <Row>
                  <Media>
                    <Media.Body>
                      <h5>{currentRequestee.fullname}</h5>
                      <p>Ranking: {Math.round(currentRequestee.ranking)} <br />
                        Ålder: </p>
                    </Media.Body>
                    {currentRequestee.profilePicture
                      ? <Image roundedCircle width={64} height={64} src={currentRequestee.profilePicture} alt="Profile placeholder" />
                      : <Image roundedCircle width={64} height={64} src={ProfilePlaceholder} alt="Generic placeholder" />
                    }
                  </Media>
                </Row>

                <Row>
                  <p>{currentRequest.message}</p>
                </Row>

                <Button variant="dark" block onClick={() => this.props.history.push(`${ROUTES.PROFILE}/${currentRequestee.uid}`)}>
                  Visa {this.props.firebase.nameToGenitive(currentRequestee.firstname)} profil
                </Button>

                <Button block onClick={this.acceptRequest} disabled={this.state.match.isFull}>
                  {this.state.match.isFull
                    ? `Matchen är redan fulltalig`
                    : `Acceptera ${this.props.firebase.nameToGenitive(currentRequestee.firstname)} förfrågan`
                  }
                </Button>

                <Button block variant="danger" onClick={this.denyRequest}>
                  Neka {this.props.firebase.nameToGenitive(currentRequestee.firstname)} förfrågan
                </Button>

                <Report match={this.state.match} profile={this.props.profile} request={currentRequest} user={currentRequestee} />
              </div>
              <hr />

              <Row>
                <Button variant="warning" block onClick={() => this.dismount()}>Gå tillbaka</Button>
              </Row>
            </Container>
          : [!this.state.loading &&
            <Container key="noResults" fluid>
              <Row className="justify-content-center noResults">
                <p>Det finns inga ansökningar till denna match</p>
              </Row>
              <hr />
              <Row>
                <Button variant="warning" block onClick={() => this.dismount()}>Gå tillbaka</Button>
              </Row>
            </Container>
          ]
        }
      </Col>
    );
  }
}

export default compose(withFirebase, withRouter)(RequestHandeler);