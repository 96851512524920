import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { isBrowser } from 'react-device-detect';
import { withFirebase } from '../components/Firebase';
import { version } from '../../package.json'

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import Header from '../components/Header/Header';

import * as ROUTES from '../constants/routes';

import '../styles/HelpPage.scss';

class HelpPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: null,
      loading: true
    }
  }

  addProfileListener = async () => {
    if (this.props.firebase.auth.currentUser || JSON.parse(localStorage.getItem("authUser")))
    this.profileListener = this.props.firebase.getProfileListener(this.props.firebase.auth.currentUser ? this.props.firebase.auth.currentUser.uid : JSON.parse(localStorage.getItem("authUser")).uid)
      .onSnapshot(snapshot => {
        if (this.mounted) {
          var profile = snapshot.data();
          profile.uid = this.props.firebase.auth.currentUser.uid;

          this.setState({ profile })
        }
      })
  }

  componentDidMount() {
    this.mounted = true;
    this.addProfileListener();
  }

  componentWillUnmount() {
    this.mounted = false;
    
    if (this.profileListener)
      this.profileListener();
  }

  render() {
    return (
      <div className="HelpPage">
        <Helmet>
          <title>Spelarpoolen | Hjälp</title>
        </Helmet>

        <Col className={`helpContent ${isBrowser ? 'browserMode' : 'mobileMode'}`}>
          <Header title="Spelarpoolen" loading={this.state.loading} profile={this.state.profile} />

          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                Vad är spelarpoolen och vad använder jag den till?
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    På Spelarpoolen rapporterar du in resultatet från dina spelade matcher. 
                    Efter inrapporterat resultat uppdateras din rankingpoäng efter principerna för <a href="https://sv.wikipedia.org/wiki/Elo-rating">ELO-rating</a>. 
                    Jämför din rankingpoäng mot andra och se vilken nivå du är på.
                  </p><p>
                    Använd även Spelarpoolen för att hitta nya med- och motspelare att spela mot!
                  </p><p>
                    För att få en så bra och rättvis ranking mellan alla medlemmar i spelarpoolen så uppmuntras ni att lägga upp resultat för alla matcher ni spelar. Självklart inget krav, men desto fler matcher som registreras desto bättre kommer rankingnivåerna att stämma.
                  </p><p>
                    För att ansöka om manuell höjning av din ranking, se kriterier under flik ”Häftigt, men hur fungerar rankingen?”.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                Låter bra, hur gör jag?
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <h5>Skapa egen match</h5>

                  <p>Tryck på Skapa match och fyll i fälten. Skriv gärna in lite om din nivå i fritextfältet. Välj om det skall vara en match med rankingpoäng eller vänskapsmatch. I vänskapsmatcher ges inga rankingpoäng ut. Tryck Spara - nu kommer matchen läggas upp för alla att söka.</p>
                  <p className="error">Viktigt! Även om du redan vet om 1 person som ska vara med i matchen, och du egentligen bara söker 2 spelare till en match med 4 spelare, så behöver du att du skriver att du söker 3 spelare. Då ALLA som spelar matchen måste registrera sig på matchen</p>
                  <img src="/images/guide/createMatch.png" alt="Skapa match" style={{ maxWidth: isBrowser ? "45%" : "100%" }} />
                  <img src="/images/guide/notification.png" alt="Notifikation" style={{ maxWidth: isBrowser ? "45%" : "100%" }} />
                  <p>Alla som vill ansöka om att få vara med kan göra det. Men matchskaparen är den som väljer vilka som ska få vara med. Det är alltså INTE först till kvarn som gäller.</p>
                  <p>Om någon har sökt till din match så kommer ett utropstecken (!) att dyka upp i hörnet på din match. Någon annan notis finns för tillfället inte, så matchskaparen får uppdatera sig då och då om någon har ansökt om att få vara med. Du ser vilka som har sökt genom att trycka på din ”match” och sedan på ”Nya ansökningar”.</p>
                  <img src="images/guide/matchCard.png" alt="Match-kort" />
                  {isBrowser 
                    ? <img src="images/guide/creatorTools.png" alt="Verktyg för match" />
                    : <img src="images/guide/creatorToolsMobile.png" alt="Verktyg för match" />
                  }
                  <p>När alla saknade platser är accepterade och fyllda med spelare så kommer detta att synas på matchen, då det kommer stå att ”0 spelare söks”.</p>
                  <p>Matchskaparen bör kontakta alla bekräftade spelare (via mail för tillfället) för att säkerställa att de som sagt att de vill spela verkligen kommer.</p>
                  <p className="error">VIKTIGT! OM du inte söker efter spelare utan bara vill rapportera in ett resultat från en tidigare spelad match, så skapa matchen och skriv att du söker efter det antalet som var med och spelade (minus dig själv). För att du skall kunna rapportera resultat måste ALLA spelare som var med registrera sig på matchen.</p>

                  <h5>Sök till match</h5>
                  <p>Superenkelt. Skapa en användare och logga in. Ställ in vilka sporter du är intresserade av att se under MIN PROFIL. Gå till HITTA MATCH och tryck på den match du är intresserad av att spela. Skriv gärna nåt i din ansökan (telefonnummer och nivå är ett tips).</p>
                  <p>Om du blir accepterad till en match kommer du se matchen under MINA MATCHER.</p>
                  <p className="error">För tillfället finns inte någon notisfunktion om du blivit antagen till en match. Så till en början får du hålla lite koll själv. Eller om du blir kontaktad via mail av matchskaparen. Funktionen att få en notis när du blivit ”nekad” en match är också under utveckling, men finns alltså inte än.</p>

                  <h5>Resultatrapportering</h5>
                  <p>Gör gärna detta direkt efter avslutad match, så att inga otydligheter uppstår. Den som skapat matchen är den som rapporterar in resultatet, och detta görs genom att trycka på ”Rapportera resultat”.</p>
                  {isBrowser
                    ? <img src="images/guide/creatorTools2.png" alt="Verktyg för match" />
                    : <img src="images/guide/creatorToolsMobile.png" alt="Verktyg för match" />
                  }
                  <p>För att ”skapa” lagen som möttes så drar du det ena lagets spelare till höger och låter det andra lagets spelare vara kvar till vänster. Nu har vi lag 1 och 2 och du kan markera vilket lag som vann och trycka ”Rapportera resultat”.</p>
                  <img src="images/guide/reportResult.png" alt="Rapportera resultat" style={{ maxWidth: isBrowser ? "45%" : "100%" }} />
                  <img src="images/guide/reportResult2.png" alt="Rapportera resultat" style={{ maxWidth: isBrowser ? "45%" : "100%" }} />
                  <p>Vid ett möjligt scenario där en spelare inte vill erkänna ett faktiskt resultat är det viktigt att anmäla detta till <a href="mailto:info@spelarpoolen.se">info@spelarpoolen.se</a>.</p>
                  <p>När ett matchresultat är inrapporterat och godkänt kommer respektive persons ranking att uppdateras. Resultat kan rapporteras in fr.o.m. angiven starttid för match.</p>
                  <p>Vid vänskapsmatcher gäller inte detta.</p>

                  <h5>Topplistan / Ranking</h5>
                  <p>Under topplistan kan du se de olika sporternas rankingtabell. Tävla gärna med varandra om att försöka komma så högt upp på rankingen som möjligt.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                Häftigt, men hur fungerar rankingen?
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>Formeln är uppbyggd så att ni får mer rankingpoäng om ni vinner mot ett bra lag/spelare och mindre rankingpoäng om du vinner mot ett lägre rankat lag/spelare.</p>
                  <p>Rankingformeln lägger ihop respektive lags totala rankingpoäng och delar denna med 2.</p>
                  <p>Exempel (<span className="teamTwo">Lag 2</span> förväntas vinna): <br/>
                    <span className="teamOne">Lag 1:</span> 1500p + 1000p / 2 = 1250p. <span className="teamTwo">Lag 2:</span> 1500 + 1400 / 2 = 1450p.</p>
                  <p>Vilket gör att om du spelar med en ”sämre” rankad spelare så kommer du inte att förlora jättemycket rankingpoäng om du förlorar. Men däremot öka mycket i ranking om ni lyckas vinna!</p>
                  <p>Man skall därför inte vara rädd att spela med ”sämre” spelare, då man inte har mycket att förlora, men däremot mycket att vinna <span role="img" aria-label="smiley">😊</span>!</p>
                  <p>På det här sättet kommer allas ranking sätta sig ganska snabbt, så länge som alla lägger in sina spelade matcher.</p>
                  <p>OM du är medlem i ett seriespel, tävlingsspelar eller är rankad i en nationell ranking kan du <b>ansöka om att få uppgradera dina rankingpoäng utan att spela matcher.</b> 
                    Du gör detta genom att maila <a href="mailto:info@spelarpoolen.se?subject=Ansökan om höjd ranking">info@spelarpoolen.se</a> och fråga om att uppgradera dina rankingpoäng. 
                    För att vi skall kunna göra det krävs att du bifogar en länk där vi kan finna resultat från seriespel, tävlingar eller liknande. Meddela också gärna annan Info som kan hjälpa oss i uppfattningen av hur mycket vi skall höja din ranking. 
                    Efter att ha läst din förfrågan kommer vi att göra en egen bedömning på hur mycket vi skall höja din ranking. Vi gör dessa manuella höjningar för att alla så snabbt som möjligt skall komma upp på en nivå som känns rimlig för dig.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>


            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">
                Jag saknar den sport jag utövar eller staden jag bor i, kan ni lägga till?
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>Det finns ett begränsat antal städer och sporter inlagda för tillfället. Detta är föra att det ska vara möjligt att hitta varandras matcher medans antalet användare växer.</p>
                  <p>Om du vill att vi lägger till en sport eller stad så vänligen maila oss på <a href="mailto:info@spelarpoolen.se?subject=Lägg till stad/sport">info@spelarpoolen.se</a> så kommer den läggas till när vi ser att det finns behov.</p>
                  <p>Under tiden, använd de alternativ som finns och specificera i beskrivningen vad du önskar. T.ex. kan matchen stå under "Stockholm" där det i beskrivningen förtydligas med "Solna med omnejd".</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>


            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="4">
                Allt verkar bra, men jag skulle vilja kontakta er och ge er tips, hur gör jag då?
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p>Om du vill komma i kontakt med oss så är du välkommen att maila oss på <a href="mailto:info@spelarpoolen.se">info@spelarpoolen.se</a>.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>


            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="5">
                Coolt, finns det nåt annat jag bör tänka på?
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p>Det kan ibland vara bra att uppge telefonnummer för med- och motspelare för underlätta er kommunikation.</p>
                  <p>Spelarpoolen är inte en bokningssida. Utan all tidsbokning hos hallar måste ske på annan portal.</p>
                  <p>Spelarpoolen är inte ansvarig för att en inbokad spelare inte dyker upp. Det kan vara bra att knyta kontakt över sms innan match för att säkerställa att alla dyker upp. 
                    Om en spelare mot förmodan inte skulle dyka upp är det bra om ni mailar detta till <a href="mailto:info@spelarpoolen.se?subject=Spelare dök ej upp">info@spelarpoolen.se</a> och anmäler detta.
                    Vid en sådan anmälan, vänligen skriv så mycket info ni kan om matchen och spelaren.</p>
                  <p>Hur betalning av t.ex. banhyra skall fördelas och betalas mellan deltagare, är deltagarnas ansvar och spelarpoolen har inget ansvar för utebliven betalning från en eller flera deltagare. 
                    Det är därför rekommenderat att ni löser betalning och swish till varandra i anslutning till att match spelas.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>


            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="6">
                Aha användarvillkoren. Ja dem kommer jag såklart att ”läsa igenom noggrant”.
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p>Användarvillkoren kan hittas <a href={ROUTES.TERMS}>här</a>.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>


            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="7">
                Och integritetspolicy är också viktigt!
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p>Integritetspolicyn kan hittas <a href={ROUTES.PRIVACY}>här</a>.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>


            <p style={{ textAlign: 'center', color: '#999' }}>Spelarpoolen v{version}</p>
          </Accordion>
        </Col>
      </div>
    )
  }
}

export default withFirebase(HelpPage);