import React from 'react';
import { Helmet } from 'react-helmet-async';
import { isBrowser } from 'react-device-detect';

import '../styles/PrivacyPolicy.scss';

export default () => (
  <div className={`privacyPolicy ${isBrowser ? 'browserMode' : 'mobileMode'}`}>
    <Helmet>
      <title>Spelarpoolen | Integritetspolicy</title>
    </Helmet>
    <h1>Integritetspolicy</h1>

    <h4>SLUTANVÄNDARE INTEGRITETSPOLICY</h4>
    <p> 
      •	Vi har skapat denna sekretesspolicy för att skydda integriteten för slutanvändaren.
      <br/>
      •	Vänligen läs nedanstående för att lära dig mer om vår integritetspolicy och hur vi hanterar den personliga information som samlats in från våra användare.
    </p>

    <h4>VAD OMFATTAR POLICYN</h4>
    <p>
      •	Denna sekretesspolicy täcker vår behandling av personlig information som samlas in av oss genom en webbplats som ägs och drivs av oss bakom SPELARPOOLEN.
    </p>

    <h4>INSAMLING OCH ANVÄNDNING AV PERSONUPPGIFTER</h4>
    <p>
      •	Du kan besöka våra webbplatser utan att lämna ut någon personlig information.
      <br/>
      •	Men vi behöver viss personlig information om du vill använda våra tjänster eller registrera ett konto.
      <br/>
      •	Om och när så krävs, kommer denna information inkludera dina kontaktuppgifter, där din mail kommer att vara din prioriterade kontaktväg.
      <br/>
      •	Vi kommer att använda denna information för att besvara dina frågor, för att andra användare skall kunna kontakta dig vid event, för att förse dig med efterfrågade produkter och tjänster, för att ställa in ditt konto, och för att kontakta dig med eventuella meddelanden och förfrågningar.
      <br/>
      •	Din profil kommer att vara synlig för andra i spelarpoolens topplista, i samband med att du fått rankingpoäng vid din första match. Dock endast namn, profilbild och rankingpoäng. Ej mail eller telefonnummer.
      <br/>
      •	Vid ansökan till match eller skapande av match kommer accepterade spelare för matchen att få tillgång till dina registrerade kontaktuppgifter, för att kunna kontakta dig och övriga i matchen med information om matchen och säkerställa att matchen blir av.
      <br/>
      •	Genom att besöka våra tjänster och frivilligt förse oss med de begärda personuppgifterna, samtycker du till insamling och användning av information i enlighet med denna Integritetspolicy.      
      <br/>
      •	Vid användning av tjänsten och ditt användande av ranking-matcher kommer ditt namn och din profilbild att vara synlig för besökare på spelarpoolen.se, under Topplistan. Där profilbild, namn och rankingpoäng visas.
      <br/>
      •	Din e-mail och eventuella telefonnummer kommer endast att vara synligt för dem i samma match, som du skapat eller ansökt om att få gå med i. När resultatet från en match är inrapporterat kommer denna information att försvinna från de andra deltagarna i matchen.
    </p>

    <h4>INSAMLING OCH ANVÄNDNING AV ICKE-PERSONLIG INFORMATION</h4>
    <p>
      •	Vi tar automatiskt emot och registrerar vissa typer av icke-personlig information på våra servrar loggar från din webbläsare innefattande din IP-adress, cookie och webbsidan du har begärt.
      <br/>
      •	Vi kan använda denna information för att anpassa den reklam och det innehåll du ser.
      <br/>
      •	Vi kopplar inte dessa icke-personliga uppgifter till någon personlig information som samlas in från dig.
      <br/>
      •	Vi kan även tillåta att utomstående företag som har annonser på vår hemsida att installera cookies på din dator.
      <br/>
      •	Dessa cookies är inte anslutna till någon personlig information på något sätt.
      <br/>
      •	Tredje part Cookieanvändning är föremål för deras egen sekretesspolicy, och vi tar inget ansvar för deras användning.
    </p>

    <h4>INFORMATION OM DELANDE OCH AVSLÖJANDE</h4>
    <p style={{ marginBottom: '0px' }}>
      •	Vi kan dela personlig information för dessa ändamål endast med tredje parter vars privacy policy är förenlig med vår eller som accepterar att följa vår politik när det gäller personlig information.
      <br/>
      •	Vi kan på annat sätt lämna ut din personliga information när:
    </p>
    <ul>
      <li>Vi har ditt uttryckliga samtycke till att lämna ut för ett visst ändamål.</li>
      <li>Vi måste svara på förelägganden, domstolsbeslut eller annan sådan juridisk process.</li>
      <li>Vi behöver skydda den personliga säkerheten för användare av våra webbplatser eller försvara rättigheter eller egendom som härrör från oss.</li>
      <li>Vi finner att din aktivitet på våra webbplatser bryter mot våra användarvillkor dokument eller någon av våra riktlinjer för användning som gäller för motsvarande produkter eller tjänster.</li>
    </ul>


    <h4>SAMTYCKE</h4>
    <p>
      •	Om du inte samtycker till insamling, användning eller utlämnande av dina personuppgifter som beskrivs i denna policy, ska du inte uppge någon personlig information till oss.
      <br/>
      •	Om du har skickat in personuppgifter till oss och vill sluta att medge användning eller utlämnande som beskrivs häri, vänligen meddela oss på <a href="mailto:info@spelarpoolen.se">info@spelarpoolen.se</a>.
    </p>

    <h4>SÄKERHET</h4>
    <p>
      •	Spelarpoolen arbetar hela tiden för att alla dataöverföringar ska vara så säkra som möjligt. Tyvärr kan dock ingen dataöverföring via Internet anses vara 100% säker.
      <br/>
      •	Däremot lagras dina uppgifter till oss som är skyddad för din integritet och säkerhet.
      <br/>
      •	Vi skyddar också din personliga information från obehörig åtkomst, genom förfaranden åtkomstkontroll, brandväggar nätverk och fysiska säkerhetsåtgärder.
      <br/>
      •	Vidare behåller vi dina personuppgifter bara så länge som krävs för att uppfylla de ändamål som anges ovan eller som krävs enligt tillämplig lag.
      <br/>
      •	Vi följer bransch-standards och använder SSL-certifiering för anslutning, samt använder en lösning från Google för att kryptera och lagra ditt lösenord säkert.
    </p>

    <h4>ÄNDRINGAR TILL DENNA SEKRETESSPOLICY</h4>
    <p>
      •	Vi kan när som helst, utan föregående meddelande till dig och efter eget gottfinnande, ändra denna policy från en tid till en annan.
      <br/>
      •	Läs igenom denna policy regelbundet.
      <br/>
      •	Din fortsatta användning av våra webbplatser gör att du accepterar sådana ändringar och innebär att du accepterar detta.
    </p>
  </div>
)