export const SPORTLIST = [
  'BADMINTON',
  'BANDY',
  'BASEBALL',
  'BASKETBALL',
  'POOL',
  'BOWLING',
  'FLOORBALL', 
  'HOCKEY',
  'FOOTBALL',
  'GOLF',
  'PADEL',
  'SQUASH',
  'TABLETENNIS',
  'TENNIS', 
  'VOLLEYBALL',
];  

export const BADMINTON = 'badminton';
export const BANDY = 'bandy';
export const BASEBALL = 'baseboll';
export const BASKETBALL = 'basket';
export const BOWLING = 'bowling';
export const FLOORBALL = 'innebandy';
export const FOOTBALL = 'fotboll';
export const GOLF = 'golf';
export const HOCKEY = 'ishockey';
export const PADEL = 'padel';
export const POOL = 'biljard';
export const SQUASH = 'squash';
export const TABLETENNIS = 'pingis';
export const TENNIS = 'tennis';
export const VOLLEYBALL = 'volleyboll';
