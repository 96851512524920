import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import ProfilePlaceholder from '../../constants/ProfilePlaceholder.png';

import * as ROUTES from '../../constants/routes';

import './Report.scss';

const INITIAL_STATE = {
  descriptionPlaceholder: `Beskriv ditt ärende ytterligare \n\nOvanstående ID:n samt ditt användar-ID kommer skickas med automatiskt i rapporten.`,
  description: '',
  reason: '',
  error: '',
  submitted: false,
  loading: false
};

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, showModal: false };
  }

  onSubmit = e => {
    e.preventDefault();

    var reportee = this.props.firebase.auth.currentUser.uid;
    var matchToReport = this.props.match ? this.props.match : null;
    var requestToReport = this.props.request ? this.props.request : null;
    var userToReport = this.props.user ? this.props.user : null;

    if (matchToReport && !(this.state.description in ["badMatchText", "bugVisual", "bugFunctional", "other"]))
      matchToReport = null;

    this.props.firebase
      .reportUserOrMatch(userToReport, matchToReport, requestToReport, reportee, this.state.reason, this.state.description)
      .then(() => {
        this.setState({ ...INITIAL_STATE, submitted: true });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onShow = (bool) => {
    this.setState({ submitted: false, showModal: true })
  }

  render() {
    return (
      <div className="Report">
        <Button variant="danger" onClick={() => this.onShow(true)} block>Anmäl missbruk</Button>

        <Modal className="Report" size="lg" show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <FontAwesomeIcon size="1x" icon={faExclamationCircle} /> Rapportera missbruk
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.state.submitted ?
              <div>
                <Media>
                  <Media.Body>
                    <h5>Du rapporterar: {this.props.user.fullname} {this.props.match && !this.props.request && 'eller hens match'}{this.props.request && 'eller hens ansökan'}</h5>
                    <p>Användar ID: {this.props.user.uid}
                      {this.props.match && <br />}
                      {this.props.match && (`Match ID: ${this.props.match.id}`)}
                    </p>
                  </Media.Body>
                  {this.props.user.profilePicture
                    ? <Image roundedCircle width={50} height={50} src={this.props.user.profilePicture} alt="Profile placeholder" />
                    : <Image roundedCircle width={50} height={50} src={ProfilePlaceholder} alt="Generic placeholder" />
                  }
                </Media>

                <Form onSubmit={(e) => this.onSubmit(e)}>
                  <Form.Control name="reason" as="select" value={this.state.reason} onChange={(e) => this.onChange(e)} disabled={!this.props.profile}>
                    <option disabled value="">Välj orsak</option>
                    {this.props.match && !this.props.request &&
                      <optgroup label="Match">
                        <option value="badMatchText">Olämplig matchbeskrining</option>
                      </optgroup>
                    }
                    {this.props.request &&
                      <optgroup label="Ansökan">
                        <option value="badRequestText">Olämplig ansökningstext</option>
                      </optgroup>
                    }
                    {this.props.user &&
                      <optgroup label="Användare">
                        <option value="profilePicture">Olämplig profilbild</option>
                        <option value="offensiveUsername">Olämpligt visningsnamn</option>
                        <option value="userdataModified">Hackning, modifierad användardata/ranking</option>
                      </optgroup>
                    }
                    <optgroup label="Övrigt">
                      <option value="bugVisual">Bug, visuella problem</option>
                      <option value="bugFunctional">Bug, funktionella problem</option>
                      <option value="other">Annat (vänligen beskriv nedan)</option>
                    </optgroup>
                  </Form.Control>
                  {!this.props.profile
                    ? <div className="textarea">
                        <p><Link to={ROUTES.LOGIN}>Logga in</Link> för att rapportera missbruk</p>
                      </div>
                    : <Form.Control name="description" as="textarea" rows="3" placeholder={this.state.descriptionPlaceholder} value={this.state.description} onChange={(e) => this.onChange(e)} />
                  }
                  {this.props.profile && 
                    <Button type="submit" block>
                      Skicka
                    </Button>
                  }
                </Form>
              </div>
              : <p>Anmälan skickad, du kan nu stänga denna ruta.</p>
            }
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withFirebase(Report);