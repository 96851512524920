import React from 'react';
import { isMobile } from 'react-device-detect';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import SignOut from '../../components/SignOut';
import ProfilePlaceholder from '../../constants/ProfilePlaceholder.png';

import './Header.scss';

import * as ROUTES from '../../constants/routes';

export default (props) => (
  <div className={`headerBox ${isMobile ? 'mobile' : 'browser'}`}>
    <Row>
      {isMobile && 
        <Col className="d-flex justify-content-center" xs={2}>
          <img src="/images/SpelarpoolenNoText.svg" width="48px" height="48px" alt="Spelarpoolen logo" />
        </Col>
      }
      <Col xs={isMobile ? 8 : 10} className="titleBox">
        <div>
          <h1>{props.title}</h1>
          {!isMobile && <p className="desc">{props.desc}</p>}
        </div>
      </Col>
      <Col className={`d-flex ${isMobile ? "justify-content-center" : "justify-content-end"}`} xs={2}>
        {props.profile && !props.profilePage 
          ? props.profile.profilePicture
            ? isMobile && <Image as="card-img" roundedCircle width={45} height={45} src={props.profile.profilePicture} alt="ProfilePic placeholder"/>
            : isMobile && <Image as="card-img" roundedCircle width={45} height={45} src={ProfilePlaceholder} alt="Generic placeholder"/>
          : !props.profilePage 
            ? !props.loading && <Button onClick={() => props.history.push(ROUTES.ME)} block>Logga in</Button>
            : !props.loading && <SignOut />
        }
      </Col>
    </Row>
    <hr style={{ marginTop: '-10px' }}/>
    {isMobile && 
      <p className="desc">
        {props.desc}
      </p>
    }
  </div>
)