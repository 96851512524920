import React from 'react';
import { Helmet } from 'react-helmet-async';

import Button from 'react-bootstrap/Button';

import Header from '../components/Header/Header';

export default () => (
  <div style={{ margin: '15px' }}>
    <Helmet>
      <title>Spelarpoolen | 404: Not found</title>
    </Helmet>

    <Header title="Page not found" desc="Error 404: Not found" loading={true} />

    <div style={{textAlign: 'center', paddingTop: '20%'}}>
      <h2 style={{color: '#eee'}}>There is no such route!</h2>
      <Button variant="light" onClick={() => window.history.go(-1)}>Click here to go back</Button>
    </div>
  </div>
)