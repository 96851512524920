import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { withFirebase } from '../Firebase';

import Form from 'react-bootstrap/Form';
// import Tooltip from 'react-bootstrap/Tooltip';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCheck, faTimes, faUserLock } from '@fortawesome/free-solid-svg-icons';

import './EditableUserInfo.scss';

class EditableUserInfo extends Component {
  constructor(props) {
    super(props)

    this.state = { 
      editableProfile: this.props.editable,
      userData: this.props.fieldData,
      editedData: this.props.type !== 'date'
        ? this.props.fieldData ? this.props.fieldData : ''
        : this.props.fieldData && this.props.firebase.timeConverter(this.props.fieldData.seconds, true),
      maxDate: this.props.firebase.timeConverter(Date.now() / 1000, true),
      editing: false
    }
  }

  onSubmit = e => {
    var dataToSubmit = this.state.editedData

    if (this.props.type === 'date') {
      try {
        dataToSubmit = new Date(this.state.editedData)
        if (isNaN(dataToSubmit)) throw new Error('Invalid Date');
      }
      catch {
        this.setState({ editedData: this.props.firebase.timeConverter(this.props.fieldData.seconds, true), editing: false })
        return;
      }
    }

    this.props.firebase.updateUserData({ [this.props.field]: dataToSubmit })
      .then(
        this.props.type !== 'date'
          ? this.setState({ userData: dataToSubmit, editing: false })
          : this.setState({ userData: { seconds: dataToSubmit.valueOf() / 1000 }, editing: false })
      )
      .catch(error => {
        console.log(error)
        alert('Oops, något gick fel!')
      })

    e.stopPropagation();
  }

  onChange = e => {
    this.setState({ editedData: e.target.value });
  };

  toggleEditing = (e, bool) => {
    if (!bool) 
      this.setState({ editing: bool, editedData: this.props.type !== 'date'
                        ? this.props.fieldData ? this.props.fieldData : ''
                        : this.props.fieldData && this.props.firebase.timeConverter(this.props.fieldData.seconds, true) })
    else if (this.state.editableProfile) 
      this.setState({ editing: bool })

    e.stopPropagation();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props)
      this.setState({
        editableProfile: this.props.editable,
        userData: this.props.fieldData,
        editedData: this.props.type !== 'date'
          ? this.props.fieldData ? this.props.fieldData : ''
          : this.props.fieldData && this.props.firebase.timeConverter(this.props.fieldData.seconds, true),
      })
  }

  render() {
    return (
      <div className="editableInfoShell">
      {this.props.secret && 
        <i className="secret">
            {/* <Tooltip id={`tooltip-${this.props.field}`} show={true}>
              Denna info är bara synlig för dig och de du spelar med.
            </Tooltip> */}
            <FontAwesomeIcon icon={faUserLock} />
        </i>
      }
        <p className={`editableInfo ${isMobile ? 'mobile' : ''}`} onClick={(e) => this.toggleEditing(e, true)}>
          {this.props.label}:&nbsp;
          {!this.state.editing
            ? [this.state.userData
              ? [this.props.type !== 'select'
                ? [this.props.field !== 'birthdate'
                  ? this.state.userData
                  : `${this.props.firebase.timestampToAge(this.state.userData.seconds)} år`
                ]
                : this.props.options && this.props.options.map((option, k) => 
                    (option.value === this.state.userData && 
                      [option.label 
                        ? option.label 
                        : option.value
                      ]
                    )
                  )
              ]
              : <i key="defaultFallback">Ej angett</i>
            ]
            : null
          }
          {this.state.editableProfile && this.state.editing
            ? <i>
              {this.props.type !== 'select'
                ? [this.props.type === 'date'
                    ? <Form.Control key="1" type={this.props.type} value={this.state.editedData} onChange={this.onChange} max={this.state.maxDate} />
                    : <Form.Control key="2" type={this.props.type} value={this.state.editedData} onChange={this.onChange} />
                  ]
                : <Form.Control as="select" onChange={this.onChange} value={this.state.editedData}>
                    <option value=""></option>
                    {this.props.options.map((option, k) => (
                      <option key={k} value={option.value}>{option.label ? option.label : option.value}</option>
                    ))}
                  </Form.Control>
              }
              <FontAwesomeIcon icon={faCheck} onClick={(e) => this.onSubmit(e)} />
              <FontAwesomeIcon icon={faTimes} onClick={(e) => this.toggleEditing(e, false)} />
            </i>
            : this.state.editableProfile && <FontAwesomeIcon icon={faPencilAlt} />
          }
        </p>
      </div>
    )
  }
}

export default withFirebase(EditableUserInfo);