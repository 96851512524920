import React from 'react';
import { Helmet } from 'react-helmet-async';
import { isBrowser } from 'react-device-detect';

import '../styles/PrivacyPolicy.scss';

import * as ROUTES from '../constants/routes';

export default () => (
  <div className={`privacyPolicy ${isBrowser ? 'browserMode' : 'mobileMode'}`}>
    <Helmet>
      <title>Spelarpoolen | Användarvillkor</title>
    </Helmet>
    <h1>Användarvillkor</h1>

    <h4>Allmänt</h4>
    <p> 
      För att kunna använda spelarpoolens tjänster måste du acceptera de allmänna villkor som beskrivs härunder. 
      Genom att acceptera villkoren förbinder du dig att följa villkoren i sin helhet, samt godkänner att du tagit del av informationen om personuppgifter och samtyckt till användningen av personuppgifter och cookies. 
      Vår Integritetspolicy återfinns <a href={ROUTES.PRIVACY}>här</a>.
    </p>

    <h4>Personuppgifter</h4>
    <p>
      Alla personuppgifter som du lämnar till oss eller som vi får del av kommer att behandlas i enlighet med Dataskyddsförordningen. 
      De personuppgifter du lämnar ut har som syfte att underlätta för användare i sitt sökande efter spelare och ranking.
    </p>

    <h4>Skapa matcher</h4>
    <p>
      Att skapa en match är enkelt. Mer information kring hur du skapar matcher finner du under ”HJÄLP - Att skapa en match”. Viktigt att komma ihåg är att Spelarpoolen inte är en sida där du bokar upp banor/planer för dina matcher, utan att du måste göra detta på en separat plattform, på den plats du vill spela. Spelarpoolen friskriver sig allt ansvar från missförstånd mellan spelare där man tror att en bana är bokad, om man gör det via spelarpoolen.
      </p><p>
      För att undvika missförstånd skall den som skapat en match ALLTID höra av sig till de som antagits för matchen. Detta skall göras i god tid innan matchen och ge info om matchen som de övriga kan behöva. T.ex. kod till hallen och eventuellt telefonnummer till dig som skapat matchen.
      </p><p>
      Det är inte tillåtet att skapa ”fejk”-matcher (matcher som fysiskt inte spelas) för att påverka ranking eller liknande. Om en användare misstänks för detta, har Spelarpoolen rätt att stänga av denna användare från Spelarpoolen.
      </p><p>
      Att däremot skapa matcher som faktiskt spelas, men där man inte hittat sitt motstånd via Spelarpoolen, uppmuntras. Då detta kommer att leda till ranking på respektive spelare, som kommer att stämma bättre med verkligheten.
      </p><p>
      Så använd gärna Spelarpoolen för er egna interna ranking i kompisgänget. Men lägg inte in matcher som faktiskt inte spelats.
      </p><p>
      Om en spelare mot förmodan inte skulle dyka upp till bestämd plats och tid, så läggs inget ansvar på Spelarpoolen för detta. All kommunikation och överenskommelser mellan spelare för betalning och liknande skall respektive användare lösa själv.
      </p><p>
      Du kan välja mellan två typer av matcher att skapa: Rankingmatch eller Vänskapsmatch. Där rankingmatcher kommer att påverka deltagarnas rankingpoäng, och där tanken är att alla spelare som spelar rankingmatcher kommer att hamna på rätt nivå efter ett par omgångar. Rankingpoäng kommer att synas i dina skapade och ansökta matcher. Vänskapsmatcher påverkar inte din rankingpoäng, utan är mer som det låter som, en ren vänskapsmatch.
      </p><p>
      Om du vid ansökan från annan spelare uppfattar ansökan som stötande, rasistisk, hotande eller liknande skall detta anmälas till oss på spelarpoolen på <a href="mailto:info@spelarpoolen.se">info@spelarpoolen.se</a>.
    </p>

    <h4>Ansöka match</h4>
    <p>
      Att ansöka till en match är superenkelt. Men om du undrar något så finns information under ”HJÄLP – Att söka till en match”.
      </p><p>
      Sök endast till matcher du vet att du kommer kunna spela. Bara för att du ansöker om att få spela en match, betyder det inte att du kommer att få spela den. Den som har skapat matchen avgör vilka av de sökande som han eller hon vill spela mot. Om du inte blir accepterad för någon match, för den tid och plats du önskar att spela, så rekommenderar vi att du skapar en egen match, där du själv vet att du kommer att få spela.
      </p><p>
      Om du blir antagen till en match kommer du att se detta under ”Mina matcher”. En rekommendation för när man blivit antagen till en match är att man hör av sig till den som skapat matchen för att säkerställa att bana är bokad och att du får den information du behöver, t.ex. vart ni ska ses, bekräfta att matchen blir av, byta nummer med varandra, osv. Allt som ni känner att ni behöver för att säkerställa att matchen kommer att bli av.
      </p><p>
      Om en spelare mot förmodan inte skulle dyka upp till bestämd plats och tid, så läggs inget ansvar på Spelarpoolen för detta. All kommunikation och överenskommelser mellan spelare för betalning och liknande skall respektive användare lösa själv.
    </p>

    <h4>Antagen till en match</h4>
    <p>
      Om du ansökt om att få spela en match kommer du inte att få en ”klassisk” notis om du blivit accepterad eller ej. Utan matchen kommer att dyka upp under ”Mina matcher”, samt sannolikt få ett mail från den som skapat matchen.
      </p><p>
      VIKTIGT! Du kommer alltså inte att få en notis om du nekas till att spela en match. Eller om du är den som först ansöker till en match. Tiden för din ansökan har ingenting att göra med om du blir accepterad till en match eller inte. Vem som spelar en match avgör alltid matchskaparen och går inte att överklaga.
      </p><p>
      Om du vill vara säker på att få vara med på en match är det bättre att skapa en egen match.
    </p>

    <h4>Rapportering av resultat</h4>
    <p>
      Vid starttiden för matchen kommer även matchresultat vara möjligt att fylla i. Den som skapat matchen är också den som fyller i resultat och anger vem som spelat med vem. Detta fylls i under ”Skapa match”. När angivet resultat rapporteras in så uppdateras även respektive spelares ranking.
      </p><p>
      VIKTIGT! För att det inte skall bli några dispyter om resultat i efterhand, så uppmanas alla användare av spelarpoolen att uppdatera resultat direkt efter match, när alla deltagare är samlade.
      </p><p>
      Vid felaktigt angivet resultat av match skall ni maila <a href="mailto:info@spelarpoolen.se">info@spelarpoolen.se</a> och meddela detta. 
    </p>

    <h4>Uppdatering ranking</h4>
    <p>
      När du börjar använda SPELARPOOLEN kommer din ranking vara svår att jämföra med andra spelare. Men desto fler som använder spelarpoolen och rapporterar resultat från sina matcher, desto bättre kommer också ranking och jämförelse mot andra bli.
      </p><p>
      I och med att du godkänner användarvillkoren är du även införstådd med att alla spelares ranking, till en början, inte kommer stämma med deras spelkvalitet till 100%. Utan att en persons ranking är en riktlinje för hur bra en spelare är, men att denna ranking även kan vara missvisande i vissa fall. Om en person t.ex. enbart mött samma personer under alla andra tidigare matcher.
      </p><p>
      Din rankingpoäng uppdateras i samband med att matchens resultat är inrapporterat. Om den spelare som skapat matchen inte rapporterar in något resultat, och detta anmäls av någon annan spelare så kommer spelaren som inte rapporterat ett resultat att få en varning. Vid flera varningar stängs spelaren av.
      </p><p>
      Du får mer rankingpoäng om du vinner mot en bra spelare och mindre om du vinner mot en lägre rankad spelare. Din rankinguppdatering påverkas även av hur ofta du spelar.
      </p><p>
      Om du är medlem i ett seriespel, tävlings-spelar eller är rankad i en nationell ranking kan du ansöka om att få uppgradera dina rankingpoäng utan att spela matcher. 
      Du gör detta genom att maila <a href="mailto:info@spelarpoolen.se?subject=Ansökan om höjd ranking">info@spelarpoolen.se</a> och fråga om att uppgradera dina rankingpoäng. 
      För att vi skall kunna göra det krävs att du bifogar en länk där vi kan finna resultat från seriespel, tävlingar eller liknande. Meddela också gärna annan Info som kan hjälpa oss i uppfattningen av hur mycket vi skall höja din ranking. 
      Efter att ha läst din förfrågan kommer vi att göra en egen bedömning på hur mycket vi skall höja din ranking. 
      Vi gör dessa manuella höjningar för att alla så snabbt som möjligt skall komma upp på en nivå som känns rimlig för dig.
      </p><p>
      Spelarpoolens administratörer är de som har full bestämmanderätt om en persons ranking skall höjas manuellt och med hur mycket.
    </p>

    <h4>Felskrivning</h4>
    <p>
      Spelarpoolen reserverar sig för eventuella skrivfel och ändringar i funktioner eller information.
    </p>

    <h4>Ersättning</h4>
    <p>
      Spelarpoolen frånskriver sig allt ansvar för eventuella oegentligheter mellan användare, där en spelare t.ex. inte dyker upp, inte anser sig behöva betala sin del av hyrs bana, eller liknande. Spelarpoolen har inga ekonomiska ersättningsskyldigheter till spelarpoolens användare.
    </p>

    <h4>Tidsbokning</h4>
    <p>
      Spelarpoolen är inte kopplat till någon bokningssida, vilket innebär att alla banor/hallar/planer osv måste bokas på separat bokningssida.
    </p>

    <h4>Kommunikation</h4>
    <p>
      Spelarpoolen kan använda den kontaktinformation som du lämnat för att skicka ut ny information om spelarpoolen.se samt för att kontakta dig angående matcher, anmälningar, avstängningar, förfrågningar, ny information och uppdateringar.
    </p>

    <h4>Anmälan</h4>
    <p>
      Spelarpoolen vill uppmuntra till sportsligt spel och uppträdande på och utanför banan. Använder du Spelarpoolen för att söka efter med- och motspelare förväntar vi oss ett schysst uppträdande i samband med att ni ses för match. 
      Om en situation skulle inträffa där ni blir osams eller oeniga så förväntas ni att lösa detta själva på plats. 
      Men vid en situation där en spelare beter sig grovt osportsligt i samband med en spelad match via Spelarpoolen, så kan ni rapportera in detta till <a href="mailto:info@spelarpoolen.se">info@spelarpoolen.se</a>. 
      Flera anmälningar av en spelare kan leda till avstängning från Spelarpoolen.
    </p>

    <h4>Beteende</h4>
    <p>
      Spelarpoolen uppmanar till fair play och ett sportsligt uppträdande både på och utanför banan. Beteende i ansökningar, mailkonversationer eller information i en match som kan uppfattas som rasistiskt, hatiskt eller liknande tolereras inte och leder till avstängning från Spelarpoolen.
    </p>

    <h4>Avstängning</h4>
    <p>
      Om en användare av spelarpoolen anmäls av ett flertal spelare kommer Spelarpoolens ansvariga att göra en egen bedömning om denna spelare skall stängas av från Spelarpoolen. En spelare kommer med största sannolikhet i ett första skede få en varning. Men vid fler inrapporterade anmälningar kommer spelare att stängas av, om inte anmälningarna kan anses som felaktiga.
    </p>

    <h4>Fusk</h4>
    <p>
      Spelarpoolen skall fungera som ett rankingsystem och ett verktyg där du kan hitta med- och motspelare på rätt nivå till dina matcher. Självklart kan det vara kul att jaga rankingpoäng. Men vid uppenbart fusk i inrapporterande av matchresultat och spelade matcher kommer spelare att stängas med omedelbar verkan. 
      OM du anser dig ha en alltför låg ranking jämfört med andra aktiva användare, så kan du maila <a href="mailto:info@spelarpoolen.se?subject=Ansökan om höjd ranking">info@spelarpoolen.se</a> med resultat från seriespel, tävlingar eller liknande, för en bedömning om din ranking skall uppdateras manuellt.
    </p>

    <h4>Tekniska fel</h4>
    <p>
      Tekniska fel kan inträffa när du håller på med teknik. Om det skulle inträffa ett tekniskt fel, som på något sätt drabbar dig i ditt användande av spelarpoolens tjänster, så avskriver sig spelarpoolen allt ansvar för dessa händelser.
    </p>

    <h4>Rätt användning av tjänsten</h4>
    <p>
      Som användare av spelarpoolen binder du dig till att använda dess funktioner till det de är avsedda att göra. Nämligen att finna med och motspelare, inrapportering av resultat från spelade matcher och få en uppdaterad ranking.
    </p>

    <h4>Uppsägning konto</h4>
    <p>
      Om du önskar att säga upp och ta bort ditt konto på spelarpoolen så mailar du <a href="mailto:info@spelarpoolen.se">info@spelarpoolen.se</a> om detta.
    </p>
  </div>
)