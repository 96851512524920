import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';

import config from './config.js';
import { version } from '../../../package.json';

import * as SPORTS from '../../constants/sports';

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
    this.files = app.storage();

    // if(app.messaging.isSupported()) {
    //   this.messaging = app.messaging();
    //   this.messaging.usePublicVapidKey('BC2cF0JQaq_SrUm4fAMRUx6kZuIsoX4K51jXyPOTl4z5d4FU5iOFs5RpM6ewx6MCo2pSkQDsBMf_zEaGSAPJ94A')

    //   this.messaging.onMessage(payload => {
    //     console.log('onMessage:', payload)
    //   })
    // }
    // else
    //   this.messaging = false;

    this.facebookProvider = new app.auth.FacebookAuthProvider();

    this.lastPaginatedDoc = {};
    this.continuedPaginationTypes = 100;
    this.paginationMaxed = false;
  }

  // Auth
  // ------------------------------------------------------------
  createUserWithEmailAndPassword = (email, password, firstname, lastname) =>
    this.auth.createUserWithEmailAndPassword(email, password)
      .then(authUser => {
        var newUser = this.db.collection('users').doc(authUser.user.uid);
        var newPrivate = this.db.collection('users').doc(authUser.user.uid).collection('private').doc(authUser.user.uid);

        newUser.set({
          firstname,
          lastname,
          fullname: `${firstname} ${lastname}`,
          rankings: {},
          mySports: [],
          recentMatches: []
        })

        newPrivate.set({
          email: authUser.user.email
        })
      })
      .then(() => {
        this.auth.currentUser.updateProfile({ displayName: `${firstname} ${lastname}` });
      })

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider)
      .then(socialAuthUser => {
        if (socialAuthUser.additionalUserInfo.isNewUser) {
          var newUser = this.db.collection('users').doc(socialAuthUser.user.uid);
          var newPrivate = this.db.collection('users').doc(socialAuthUser.user.uid).collection('private').doc(socialAuthUser.user.uid);

          newUser.set({
            firstname: socialAuthUser.additionalUserInfo.profile.first_name,
            lastname: socialAuthUser.additionalUserInfo.profile.last_name,
            fullname: socialAuthUser.additionalUserInfo.profile.name,
            profilePicture: socialAuthUser.user.photoURL + '?type=large',
            rankings: {},
            mySports: [],
            recentMatches: []
          })

          newPrivate.set({
            email: socialAuthUser.additionalUserInfo.profile.email
          })
        }
      })

  signOut = () =>
    this.auth.signOut();

  resetPassword = email =>
    this.auth.sendPasswordResetEmail(email);

  updatePassword = password =>
    this.auth.currentUser.updatePassword(password);

  authUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.getProfileOnce(authUser.uid).then(dbUser => {
          // if admin tool is developed
          // if (dbUser && !dbUser.roles) {
          //   dbUser.roles = {};
          // }

          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            ...dbUser
          };

          next(authUser);
        });
      } else {
        fallback();
      }
    });

  // Cloud Messaging
  // ------------------------------------------------------------
  getNotificationPermission = () =>
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.log('Unable to get permission to notify.');
      }

      if (this.messaging)
        return this.messaging.getToken()
    })
      .then(token => {
        console.log(token)
      });


  // Get
  // ------------------------------------------------------------
  getProfileListener = (userId) => this.db.collection('users').doc(userId)

  getPrivateListener = (userId) => this.db.collection('users').doc(userId).collection('private').doc(userId)

  getProfileOnce = (userId) => this.db.collection('users').doc(userId)
    .get()
    .then(snapshot => {
      var profile = snapshot.data()
      if (profile) profile.uid = userId

      return profile;
    });

  getMatchRequests = (matchId) => this.db.collection('matches').doc(matchId).collection('requests')
    .where('matchCreatorId', '==', this.auth.currentUser.uid)
    .where('denied', '==', false)
    .get()
    .then(snapshot => {
      let requests = []
      snapshot.forEach(doc => {
        let request = doc.data()
        request.id = doc.id;

        requests.push(request)
      })
      return requests;
    })

  getRequestByYou = (matchId) => this.db.collection('matches').doc(matchId).collection('requests').doc(this.auth.currentUser.uid)
    .get()
    .then(snapshot => {
      return snapshot.exists ? snapshot.data() : false;
    })

  getPrivateInfo = async (matchId) => this.db.collection('matches').doc(matchId).collection('private').doc('private')
      .get()
      .then(snapshot => {
        return snapshot.exists ? snapshot.data() : false;
      })

  getAllMatches = () => this.db.collection('matches')
    .get()
    .then(snapshot => {
      let matches = []
      snapshot.forEach(doc => {
        let match = doc.data()
        match.id = doc.id;

        match.startTimeStr = this.timeConverter(match.startTime.seconds)
        match.endTimeStr = this.timeConverter(match.endTime.seconds, false, false, match.startTimeStr)

        matches.push(match)
      })
      return matches;
    })

  getMatchById = (id) => this.db.collection('matches').doc(id)
    .get()
    .then(doc => {
      let match = doc.data()
      match.id = doc.id;

      match.startTimeStr = this.timeConverter(match.startTime.seconds)
      match.endTimeStr = this.timeConverter(match.endTime.seconds, false, false, match.startTimeStr)

      return match;
    })

  getMatchesByParticipant = async (userId = this.auth.currentUser.uid) => {
    var queryLimit = 5;
    
    return this.db.collection('matches')
      .where('acceptedPlayerIDs', 'array-contains', userId)
      .where('hasResult', '==', false)
      .orderBy('startTime')
      .limit(queryLimit)
      .get()
      .then(snapshot => {
        let matches = []
        snapshot.forEach(doc => {
          let match = doc.data()
          match.id = doc.id;
          
          match.startTimeStr = this.timeConverter(match.startTime.seconds)
          match.endTimeStr = this.timeConverter(match.endTime.seconds, false, false, match.startTimeStr)

          matches.push(match)
        })
        return matches;
      })
    }

  getMatchesByParams = async (sportList, city, startTime, rankedFilter, showFull = 'false', onlySelf = false, omitSelf = false, showWithResult = 'false') => {
    var queryLimit = 5;
    var paginationMaxedObj = {}

    if (sportList && sportList.length > 0 && JSON.stringify(sportList.sort()) !== JSON.stringify(SPORTS.SPORTLIST.sort())) {
      var matchList = sportList.map(sport => {
        var query = this.db.collection('matches')
        query = query.orderBy('startTime');

        if (city) query = query.where('city', '==', city)
        if (true) query = query.where('sport', '==', sport);
        if (Boolean(rankedFilter)) query = query.where('ranked', '==', rankedFilter === 'true' ? true : false);
        if (startTime) query = query.where('startTime', '>=', startTime)
        if (Boolean(showFull)) query = query.where('isFull', '==', showFull === 'true' ? true : false);
        if (onlySelf) query = query.where('author.uid', '==', this.auth.currentUser.uid)
        if (Boolean(showWithResult)) query = query.where('hasResult', '==', showWithResult === 'true' ? true : false)
        if (!(Object.keys(this.lastPaginatedDoc).length === 0) && this.lastPaginatedDoc.constructor === Object && this.lastPaginatedDoc[sport]) query = query.startAfter(this.lastPaginatedDoc[sport])

        const splitQueryLimit = Math.ceil(Math.max(queryLimit / sportList.length, queryLimit / this.continuedPaginationTypes));
        query = query.limit(splitQueryLimit);

        if (Object.keys(this.lastPaginatedDoc).length === 0 || this.lastPaginatedDoc[sport]) {
          let sportResult = query.get()
            .then(snapshot => {
              let matches = []

              this.lastPaginatedDoc[sport] = snapshot.docs[snapshot.docs.length-1];
              if (snapshot.docs.length === splitQueryLimit) paginationMaxedObj[sport] = false;

              snapshot.forEach(doc => {
                let match = doc.data()
                match.id = doc.id;

                match.startTimeStr = this.timeConverter(match.startTime.seconds)
                match.endTimeStr = this.timeConverter(match.endTime.seconds, false, false, match.startTimeStr)

                if (!(omitSelf && match.author.uid === this.auth.currentUser.uid))
                  matches.push(match)
              })
              return matches;
            })
          return sportResult;
        } else {
          return null;
        }
      })

      return await Promise.all(matchList).then(values => {
        if ((Object.keys(paginationMaxedObj).length === 0 && paginationMaxedObj.constructor === Object))
          this.paginationMaxed = true;
        else 
          this.continuedPaginationTypes = Object.keys(paginationMaxedObj).length;

        var result = [];
        values.map(value => Array.prototype.push.apply(result, value))
        return result;
      })
    } else {
      var query = this.db.collection('matches')
      query = query.orderBy('startTime');

      if (city) query = query.where('city', '==', city)
      if (Boolean(rankedFilter)) query = query.where('ranked', '==', rankedFilter === 'true' ? true : false)
      if (startTime) query = query.where('startTime', '>=', startTime)
      if (Boolean(showFull)) query = query.where('isFull', '==', showFull === 'true' ? true : false)
      if (onlySelf) query = query.where('author.uid', '==', this.auth.currentUser.uid)
      if (Boolean(showWithResult)) query = query.where('hasResult', '==', showWithResult === 'true' ? true : false)
      if (!(Object.keys(this.lastPaginatedDoc).length === 0 && this.lastPaginatedDoc.constructor === Object)) query = query.startAfter(this.lastPaginatedDoc)

      query = query.limit(queryLimit);

      let sportResult = query.get()
        .then(snapshot => {
          let matches = []
          
          this.lastPaginatedDoc = snapshot.docs[snapshot.docs.length-1];
          if (snapshot.docs.length < queryLimit) this.paginationMaxed = true;

          snapshot.forEach(doc => {
            let match = doc.data()
            match.id = doc.id;

            match.startTimeStr = this.timeConverter(match.startTime.seconds)
            match.endTimeStr = this.timeConverter(match.endTime.seconds, false, false, match.startTimeStr)

            if (!(omitSelf && this.auth.currentUser && match.author.uid === this.auth.currentUser.uid))
              matches.push(match)
          })
          return matches;
        })
      return sportResult;
    }
  }

  getLeaderboardForSport = (sport) => this.db.collection('rankings').doc(sport)
    .get()
    .then(snapshot => {
      if (snapshot.exists) {
        var doc = snapshot.data();
        var rankingArray = Object.keys(doc).map(uid => doc[uid]).sort((a,b) => b.ranking - a.ranking)

        return rankingArray;
      }
        
      return false;
    });


  // Set
  // ------------------------------------------------------------
  updateUserData = async (userObj) => {
    var authorUserObj = {}
    var done = false;

    //Check if private info
    Object.keys(userObj).map(key => {
      if (['email', 'telephone'].includes(key)) {
        this.db.collection('users').doc(this.auth.currentUser.uid).collection('private').doc(this.auth.currentUser.uid)
          .update({
            ...userObj
          })
        done = true;
      }
      return userObj;
    })
    if (done) return null;

    //Check if change involves info which needs to cascade
    Object.keys(userObj).map(key => {
      if (['firstname', 'lastname', 'fullname', 'profilePicture', 'uid'].includes(key))
        authorUserObj[`author.${key}`] = userObj[key]
      return authorUserObj;
    });

    //Get document refs to recieve cascading changes
    if (Object.keys(authorUserObj).length > 0) {
      var matchAuthorRefs = [];
      await this.db.collection('matches').where('author.uid', '==', this.auth.currentUser.uid)
        .where('hasResult', '==', false)
        .limit(5)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            matchAuthorRefs.push(doc.ref)
          })
        })
    }

    var batch = this.db.batch();

    var userRef = this.db.collection('users').doc(this.auth.currentUser.uid)
    batch.update(userRef, userObj)

    if (Object.keys(authorUserObj).length > 0) {
      matchAuthorRefs.forEach(matchRef => {
        batch.update(matchRef, authorUserObj)
      })
    }
    batch.commit()
  }

  setProfilePicture = async (file) => {
    var fileRef = this.files.ref(`profilePictures/${this.auth.currentUser.uid}/${file.name}`)

    fileRef.put(file)
      .then(() => {
        fileRef.getDownloadURL().then(fileUrl => {
          this.updateUserData({ profilePicture: fileUrl })
        })
      })
      .then(() => {
        this.deleteProfilePicture(fileRef);
      })
      .catch((error) => {
        console.log(error)
      })
  }

  deleteProfilePicture = (exception = null) => {
    var folderRef = this.files.ref(`profilePictures/${this.auth.currentUser.uid}`)

    folderRef.listAll()
      .then((res) => {
        res.items.forEach((itemRef) => {
          if (!exception || exception.name !== itemRef.name)
            itemRef.delete()
          if (!exception)
            this.updateUserData({ profilePicture: '' })
        })
      })
      .catch((error) => {
        console.log(error)
      });
  }

  reportUserOrMatch = (user, match, request, reportee, reason, description) =>
    this.db.collection('reports').doc()
      .set({
        author: reportee,
        user,
        match,
        request,
        reason,
        description, 
        version
      })
      .catch((error) => {
        console.log(error)
      })

  createNewMatch = async (matchObj, profile, privateProfile) => {
    var author = {};
    var docObj = { ...matchObj }
    
    author.uid = profile.uid
    author.firstname = profile.firstname
    author.lastname = profile.lastname
    author.fullname = profile.fullname
    author.profilePicture = profile.profilePicture
    author.ranking = profile.rankings[matchObj.sport] ? profile.rankings[matchObj.sport] : 1200

    author = this.undefinedToNullInObject(author);
    privateProfile = this.undefinedToNullInObject(privateProfile);

    delete docObj.id

    var p;
    var newRef;
    if (matchObj.id)
      p = this.db.collection('matches').doc(matchObj.id)
        .update({
          ...docObj
        })
        .catch((error) => {
          console.log(error)
        })
    else {
      newRef = this.db.collection('matches').doc()
      p = newRef.set({
          acceptedPlayers: [],
          acceptedPlayerIDs: [],
          hasResult: false,
          author,
          ...docObj
        })
        .catch((error) => {
          console.log(error)
        })

      newRef.collection('private').doc('private')
        .set({
          [author.uid]: {
            email: privateProfile.email ? privateProfile.email : '',
            telephone: privateProfile.telephone ? privateProfile.telephone : ''
          }
        })
    }

    return await p.then(() => {
      if (newRef) return { acceptedPlayers: [], author, ...docObj, id: newRef.id };
      return { acceptedPlayers: [], author, ...docObj, id: matchObj.id };
    })
  }

  deleteMatch = async (matchId) => 
    this.db.collection('matches').doc(matchId).delete()

  createMatchRequest = async (matchObj, matchCreatorId, message, profile, privateProfile) => {
    var requestee = {};

    requestee.uid = profile.uid
    requestee.firstname = profile.firstname
    requestee.lastname = profile.lastname
    requestee.fullname = profile.fullname
    requestee.profilePicture = profile.profilePicture
    requestee.ranking = profile.rankings[matchObj.sport] ? profile.rankings[matchObj.sport] : 1200

    requestee.email = privateProfile.email
    requestee.telephone = privateProfile.telephone

    requestee = this.undefinedToNullInObject(requestee);

    this.db.collection('matches').doc(matchObj.id).collection('requests').doc(profile.uid)
      .set({
        denied: false,
        message,
        matchCreatorId,
        requestee
      })
      .catch((error) => {
        console.log(error)
      })
  }

  acceptMatchRequest = async (matchObj, request) => {
    var matchDocRef = this.db.collection('matches').doc(matchObj.id);
    var requestDocRef = this.db.collection('matches').doc(matchObj.id).collection('requests').doc(request.id);
    var matchPrivateDocRef = this.db.collection('matches').doc(matchObj.id).collection('private').doc('private');

    return this.db.runTransaction(async transaction => {
      let match = transaction.get(matchDocRef)
      let request = transaction.get(requestDocRef)

      return await Promise.all([match, request]).then(docs => {
        if (!docs[0].exists || !docs[1].exists) {
          throw Error("Document does not exist!");
        }
        let matchDoc = docs[0].data();
        let requestDoc = docs[1].data();
        let isFull = false;
        if ((matchDoc.acceptedPlayers && matchDoc.numPlayersRequested <= matchDoc.acceptedPlayers.length + 1)
            || (!matchDoc.acceptedPlayers && matchDoc.numPlayersRequested === 1))
          isFull = true;
        
        var requesteePublic = JSON.parse(JSON.stringify(requestDoc));
        delete requesteePublic.requestee.email
        delete requesteePublic.requestee.telephone

        transaction.update(matchDocRef, 
          { acceptedPlayers: app.firestore.FieldValue.arrayUnion(requesteePublic.requestee),
            acceptedPlayerIDs: app.firestore.FieldValue.arrayUnion(requesteePublic.requestee.uid),
            isFull });

        transaction.set(matchPrivateDocRef, 
          { [requestDoc.requestee.uid]: {
            email: requestDoc.requestee.email,
            telephone: requestDoc.requestee.telephone }
          }, { merge: true });

        transaction.delete(requestDocRef);
  
        return requestDoc;
      });
    }).catch(err => {
      console.error(err);
    });
  }

  denyMatchRequest = async (matchId, request) =>
    this.db.collection('matches').doc(matchId).collection('requests').doc(request.id)
      .update({ 
        denied: true 
      }).then(() => {
        return request;
      }).catch(err => {
        console.error(err);
      });

  setMatchResult = async (matchId, result) => {
    var matchDocRef = this.db.collection('matches').doc(matchId);
    var resultDocRef = this.db.collection('matches').doc(matchId).collection('results').doc('result');

    var batch = this.db.batch();

    batch
      .update(matchDocRef, { 
        hasResult: true
      })
      .set(resultDocRef, {
        ...result
      });

    batch.commit();
  };

  // Helpers
  // ------------------------------------------------------------
  timeConverter = (UNIX_timestamp, forInputFields = false, forDatetimeInputs = false, startTimeStr = null) => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    if (hour < 10)
      hour = '0' + hour
    var min = a.getMinutes();
    if (min < 10)
      min = '0' + min
    var time = `${date} ${month} ${hour}:${min}`;


    if (forInputFields) {
      month = a.getMonth() + 1;
      if (month < 10)
        month = '0' + month
      if (date < 10)
        date = '0' + date

      if (forDatetimeInputs)
        time = `${year}-${month}-${date}T${hour}:${min}`;
      else
        time = `${year}-${month}-${date}`;
    }
    else if (startTimeStr && startTimeStr.slice(0, -6) === `${date} ${month}`) {
      time = `${hour}:${min}`;
    }

    return time;
  }

  timestampToAge = timestamp => {
    var diff_ms = Date.now() - timestamp * 1000;
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  undefinedToNullInObject = obj => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === "object")
        this.undefinedToNullInObject(obj[key]);
      else if (obj[key] === undefined) obj[key] = null;
    });
    return obj;
  };

  resetPagination = () => {
    this.lastPaginatedDoc = {};
    this.continuedPaginationTypes = 100;
    this.paginationMaxed = false;
  }

  nameToGenitive = (name) => {
    if (name.endsWith('s')) return name
    else return `${name}s`
  }
};

export default Firebase;