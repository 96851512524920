import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet-async';
import { isBrowser, isMobile } from 'react-device-detect';
import { Transition } from 'react-spring/renderprops';

import { withFirebase } from '../components/Firebase';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import InputGroup from 'react-bootstrap/InputGroup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmileBeam } from '@fortawesome/free-regular-svg-icons';
import PulseLoader from 'react-spinners/PulseLoader';

import Match from '../components/Match/Match';
import MatchCard from '../components/MatchCard/MatchCard';
import MatchCardSkeleton from '../components/MatchCard/MatchCardSkeleton';

import * as SPORTS from '../constants/sports';
import * as ROUTES from '../constants/routes';
import * as CITIES from '../constants/cities';

import '../styles/LandingPage.scss';
import '../components/Header/Header.scss';

const QUERY_DEFAULTS = {
  sports: '',
  city: '',
  rankedFilter: ''
}

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...QUERY_DEFAULTS,
      loading: true,
      loadingPagination: false,
      startTime: this.props.firebase.timeConverter(Date.now() / 1000, true),
      currentDate: this.props.firebase.timeConverter(Date.now() / 1000, true),
      maxDate: this.props.firebase.timeConverter((Date.now() + 60 * 60 * 24 * 365 * 1000) / 1000, true),
      matchId: '',
      toggleExpand: false
    }

    QUERY_DEFAULTS.startTime = this.props.firebase.timeConverter(Date.now() / 1000, true);
  }

  onChange = e => {
    if (e.target.type === 'checkbox')
      this.setState({ [e.target.name]: e.target.checked })
    else
      this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = (e, sportList = null, cityFrProfile = null, resetPagination = true) => {
    if (e) e.preventDefault();

    if (resetPagination)
      this.setState({ loading: true })
    else 
      this.setState({ paginationLoading: true })

    var sports = sportList;
    var city = cityFrProfile;

    if (!sportList) 
      sports = this.state.sports
    if (!cityFrProfile) 
      city = this.state.city

    try {
      if (isNaN(new Date(this.state.startTime))) throw new Error('Invalid Date');
    }
    catch {
      this.setState({ startTime: QUERY_DEFAULTS.startTime })
      return;
    }

    if (resetPagination) this.props.firebase.resetPagination();
    this.props.firebase.getMatchesByParams(sports, city, new Date(this.state.startTime), this.state.rankedFilter, undefined, undefined, true)
      .then(response => {
        if (this.mounted)
          this.setState(prevState => ({
            matches: resetPagination ? response : [...prevState.matches, ...response],
            loading: false,
            paginationLoading: false
          }))
      })
  }

  componentDidUpdate(prevProps, prevState) {
    var matchId = this.props.match.params.matchId;

    if (matchId !== prevState.matchId)
      this.setState({ matchId })
  }

  componentDidMount() {
    this.mounted = true;
    this.props.firebase.resetPagination();
    
    this.authListener = this.props.firebase.authUserListener(
      authUser => {
        this.props.firebase.getProfileOnce(authUser.uid)
          .then(profile => {
            if (this.mounted) {
              this.setState({ profile, sports: profile.mySports, city: profile.city })
              this.onSubmit(null, profile.mySports, profile.city)

            this.privateListener = this.props.firebase.getPrivateListener(authUser.uid)
              .onSnapshot(snapshot => {
                var privateProfile = snapshot.data();

                this.setState({ privateProfile })
              })
            }
          })},
        () => { this.onSubmit(null) }
    )
  }

  componentWillUnmount() {
    this.mounted = false;
    this.authListener();
    if (this.privateListener) this.privateListener();
  }

  render() {
    return (
      <div className="LandingPage">
        <Helmet>
          <title>Spelarpoolen | Sök match</title>
          <meta name="title" content="Hitta spelare, motståndare och medspelare. Ranking och topplista."/>
          <meta name="description" content={`Skapa eller sök matcher och hitta med- och motståndare i din sport. Tävla med vårt rankingsystem, som ger rankingpoäng efter varje spelad match. Gå med och se hur högt du kan klättra i ranking! Vi har ${SPORTS.SPORTLIST.map(sport => ' ' + SPORTS[sport]).toString()}`} />
        </Helmet>

        <Col className="matchQuery" md={this.state.matchId && isBrowser ? 8 : 12} sm={12}>
          <Form onSubmit={this.onSubmit}>
            <Accordion className={`headerBox ${isMobile ? 'mobile' : 'browser'}`}>

              <Form.Row>
                {isMobile && 
                  <Col className="d-flex justify-content-center" xs={2}>
                    <img src="/images/SpelarpoolenNoText.svg" width="48px" height="48px" alt="Spelarpoolen logo" />
                  </Col>
                }
                <Col xs={isMobile ? 8 : 10} className="titleBox">
                  <div>
                    <h1>Hitta match</h1>
                    {!isMobile && <p className="desc">Ansök till matcher andra skapat</p>}
                  </div>
                </Col>
                <Col className={`d-flex ${isMobile ? "justify-content-center" : "justify-content-end"}`} xs={2}>
                  <Accordion.Toggle as={Button} variant="link" aria-label="sökalternativ" eventKey="0" onClick={() => this.setState(prevState => ({ toggleExpand: !prevState.toggleExpand }))}>
                    <svg version="1.1" className={this.state.toggleExpand ? 'cross' : 'bars'} focusable="false" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="1.8rem" viewBox="0 0 448 512">
                      <path d="M16,132h416c8.8,0,16-7.2,16-16V76c0-8.8-7.2-16-16-16H16C7.2,60,0,67.2,0,76v40C0,124.8,7.2,132,16,132z"/>
                      <path d="M16,292h416c8.8,0,16-7.2,16-16v-40c0-8.8-7.2-16-16-16H16c-8.8,0-16,7.2-16,16v40C0,284.8,7.2,292,16,292z"/>
                      <path d="M16,452h416c8.8,0,16-7.2,16-16v-40c0-8.8-7.2-16-16-16H16c-8.8,0-16,7.2-16,16v40C0,444.8,7.2,452,16,452z"/>
                    </svg>
                  </Accordion.Toggle>
                </Col>
              </Form.Row>
              <hr style={{ marginTop: '-10px' }}/>
              {isMobile && 
                <p className="desc" style={{ opacity: this.state.toggleExpand ? 0 : 1, display: this.state.toggleExpand ? 'none' : 'block' }}>
                  Ansök till matcher andra skapat
                </p>
              }
              

              <Accordion.Collapse eventKey="0">
                <div>
                  <Form.Row>
                    <Form.Group as={Col} md={6} xs={12}>
                      <Form.Control name="city" value={this.state.city} onChange={this.onChange} as="select" >
                        {CITIES.CITYLIST.map((city, k) => {
                          if (city !== '')
                            return <option key={k} value={city}>{city}</option>
                          else 
                            return <option key={k} value="" disabled>Stad</option>
                        })}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} md={6} xs={12}>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">Fr.o.m:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control name="startTime" value={this.state.startTime} onChange={this.onChange}
                                      type="date" min={this.state.currentDate} max={this.state.maxDate}/>
                      </InputGroup>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} md={6} xs={12}>
                      <Form.Control name="rankedFilter" value={this.state.rankedFilter} onChange={this.onChange} as="select" >
                        <option value="">Rankade och vänskapsmatcher</option>
                        <option value="true">Rankade matcher</option>
                        <option value="false">Vänskapsmatcher</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} md={6} xs={12}>
                      <Button onClick={() => this.props.history.push(ROUTES.ME)} block>
                        {this.state.profile && this.state.sports
                          ? [this.state.sports.length > 0
                            ? `${this.state.sports.length} valda sporter, ändra vilka i din profil`
                            : `Filtrera ut de sporter du spelar i din profil`]
                          : [this.state.profile 
                            ? `Välj vilka sporter du spelar i din profil`
                            : `Logga in för att filtrera på sporter`]
                        }
                      </Button>
                    </Form.Group>

                    <Form.Group as={Col} xs={12}>
                      <Button type="submit" block>Sök</Button>
                    </Form.Group>
                  </Form.Row>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </Form>


          <div id="matchList" className={isBrowser ? 'browserMode' : 'mobileMode'}>
            {this.state.loading
              ? <div className="loadingIndication">
                  <MatchCardSkeleton/>
                  <MatchCardSkeleton style={{ opacity: 0.7 }}/>
                  <MatchCardSkeleton style={{ opacity: 0.4 }}/>
                  <MatchCardSkeleton style={{ opacity: 0.2 }}/>
                </div>
              : this.state.matches && this.state.matches.length > 0
                ? <div>
                    {this.state.matches.map((match, k) => (
                      <MatchCard key={k} matchObj={match} selected={this.state.matchId === match.id} />
                    ))}
                    <div className="paginationTools">
                      {!this.props.firebase.paginationMaxed
                        ? !this.state.loadingPagination
                          ? <p className="paginationLink" onClick={e => this.onSubmit(e, null, null, false)}>Tryck för att hämta fler matcher</p>
                          : <p>Loading more 
                              <PulseLoader
                                size={ 5 }
                                css={{ paddingLeft: '5px', display: 'inline' }}
                                loading={!this.state.loadingPagination}
                              />
                            </p>
                        : <p>Alla resultat hämtade <FontAwesomeIcon icon={faSmileBeam}/></p>
                      }
                    </div>
                  </div>
                : <div className="noResults d-flex flex-column justify-content-center" style={{ paddingTop: '15%', textAlign: 'center' }}>
                    <h4>Hittade inga matcher som passade din sökning</h4>
                    <br/><br/>
                    <p>Prova att uppdatera din sökning eller dina sporter (görs i din profil)</p>
                  </div>
            }
          </div>
        </Col>
        
        <Transition
          items={this.state.matchId}
          initial={this.props.history.action === "POP" ? null : undefined}
          from={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)' }}
          enter={{ transform: 'translate3d(0%, 0, 0)' }}
          leave={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)' }}>
          {show => show &&
            (props => 
              <Match profile={this.state.profile} privateProfile={this.state.privateProfile} transition={props} /> 
          )}
        </Transition>
      </div>
    );
  }
}

export default compose(withRouter, withFirebase)(LandingPage);