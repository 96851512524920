import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { isMobile } from 'react-device-detect';

import { withFirebase } from '../components/Firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../styles/LoginPage.scss';

import SignIn from '../components/SignIn';
import SignUp from '../components/SignUp';
import PasswordReset from '../components/PasswordReset';

import * as ROUTES from '../constants/routes';

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newUser: false
    }
  }

  createNewUser = (e, bool) => {
    e.preventDefault();
    this.setState({ newUser: bool })
  }

  forgotPassword = (e, bool) => {
    e.preventDefault();
    this.setState({ forgotPassword: bool })
  }

  render() {
    return (
      <div className="LoginPage">
        {this.props.firebase.auth.currentUser && <Redirect to={ROUTES.LANDING}/>}
        <Helmet>
          <title>Spelarpoolen | Login</title>
        </Helmet>

        <Container fluid={true}>
          <Row className="justify-content-center" style={{textAlign: 'center'}} >
            <Col xl={8} lg={10} md={12} sm={12} xs={12}>
              {isMobile 
                ? <img src="/images/Spelarpoolen.svg" width="50%" alt="Spelarpoolen logo" />
                : <h1>Spelarpoolen</h1>
              }
              <br/>
              <br/>
              {!this.state.newUser 
                ? [!this.state.forgotPassword
                    ? <SignIn key="signin" createNewUser={this.createNewUser} forgotPassword={this.forgotPassword} from={this.props.location.state ? this.props.location.state.from : ''} /> 
                    : <PasswordReset key="passwordreset" forgotPassword={this.forgotPassword} />
                  ]
                : <SignUp createNewUser={this.createNewUser} from={this.props.location.state ? this.props.location.state.from : ''} />
              }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withFirebase(LoginPage);