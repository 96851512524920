import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet-async';
import { isBrowser, isMobile, mobileModel } from 'react-device-detect';

import { withFirebase } from '../Firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import * as SPORTS from '../../constants/sports';

import './SportSelection.scss';

class SportSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enabledSports: this.props.profile.mySports ? this.props.profile.mySports : [],
      loading: true
    }
  }

  handleClick = sport => {
    var enabledSports = this.state.enabledSports

    if (enabledSports.includes(sport))
      enabledSports.splice(enabledSports.indexOf(sport), 1)
    else
      enabledSports.push(sport)
    
    this.setState({ enabledSports })
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  componentWillUnmount() {
    var enabledSports = this.state.enabledSports.sort();
    
    this.props.firebase.updateUserData({ mySports: enabledSports })
  }

  render() {
    return (
      <Col md={isBrowser ? 4 : 12} sm={12} style={this.props.transition}
          className={`sportSelection justify-content-center ${isMobile ? 'mobileOverlay' : 'browserSidebar'}
                    ${mobileModel} w${window.screen.width}h${window.screen.height}`}>
                                    
        <Helmet>
          <title>Spelarpoolen | Mina sporter</title>
        </Helmet>

        {!this.state.loading &&
          <Container fluid>
            <Row>
              <h1>Sporter</h1>

              {SPORTS.SPORTLIST.map((sport, k) => (
                <Button key={k} name={sport}
                        variant={this.state.enabledSports.includes(sport) 
                          ? 'success' 
                          : 'warning'
                        } 
                        onClick={() => this.handleClick(sport)} className="sportSelector">
                  <p>{SPORTS[sport]}</p>
                  <img src={`/images/sports/${sport}.svg`} alt={sport}/>
                </Button>
              ))}
              
              <hr />

              <Button onClick={() => this.props.saveChanges()}>Spara ändringar</Button>
            </Row>
          </Container>
        }
      </Col>
    );
  }
}

export default compose(withFirebase, withRouter)(SportSelection);