import React, { Component } from 'react';
import { matchPath } from 'react-router-dom';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet-async';
import { isBrowser, isMobile, mobileModel } from 'react-device-detect';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Auth';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import './CreateMatch.scss';

import * as SPORTS from '../../constants/sports';
import * as ROUTES from '../../constants/routes';
import * as CITIES from '../../constants/cities';

var INITIAL_STATE = {
  id: '',
  sport: '',
  numPlayersRequested: '',
  city: '',
  location: '',
  startTime: '',
  endTime: '',
  description: '',
  ranked: 'true',
  invalidData: {}
};

class MatchCreationPage extends Component {
  constructor(props) {
    super(props);

    INITIAL_STATE.city = this.props.profile.city ? this.props.profile.city : ''
    INITIAL_STATE.sport = this.props.profile.mySports && this.props.profile.mySports.length === 1 ? this.props.profile.mySports[0] : ''

    this.state = {
      ...INITIAL_STATE,
      currentDate: this.props.firebase.timeConverter(Date.now() / 1000, true),
      maxDate: this.props.firebase.timeConverter((Date.now() + 60 * 60 * 24 * 365 * 1000) / 1000, true)
    }
  }

  onChange = e => {
    if (e.target.type === 'checkbox')
      this.setState({ [e.target.name]: e.target.checked })
    else if (e.target.type === 'radio')
      this.setState({ [e.target.name]: e.target.id })
    else if (e.target.type === 'datetime-local' && e.target.value.length > 16)
      this.setState({ [e.target.name]: e.target.value.substr(0, 16) })
    else
      this.setState({ [e.target.name]: e.target.value });

    if (e.target.name === 'numPlayersRequested')
      this.setState({ invalidData: { ...this.state.invalidData, [e.target.name]: null, ranked: null } })
    else if (e.target.name === 'startTime' && isNaN(new Date(this.state.endTime)) && !isNaN(new Date(this.state.startTime).getHours() + 1))
      this.setState({ invalidData: { ...this.state.invalidData, [e.target.name]: null, endTime: null } })
    else
      this.setState({ invalidData: { ...this.state.invalidData, [e.target.name]: null } })
  };

  validateFields = (matchObj) => {
    const isInvalid = 
      matchObj.city === '' ||
      matchObj.description === '' ||
      matchObj.location === '' ||
      matchObj.numPlayersRequested < 1 ||
      matchObj.numPlayersRequested > 25 ||
      !['true', 'false'].includes(matchObj.ranked) ||
      (matchObj.ranked === 'true' && !(matchObj.numPlayersRequested % 2)) ||
      !SPORTS.SPORTLIST.includes(matchObj.sport) ||
      matchObj.startTime === '';

    var invalidData = {};

    if (matchObj.city === '') 
      invalidData = { ...invalidData, city: 'Vänligen välj en stad' }
    if (matchObj.description === '')
      invalidData = { ...invalidData, description: 'Vänligen skriv en beskrivning' }
    if (isNaN(new Date(this.state.endTime)) && isNaN(new Date(this.state.startTime).getHours() + 1))
      invalidData = { ...invalidData, endTime: 'Välj ett giltigt datum' }
    if (matchObj.location === '')
      invalidData = { ...invalidData, location: 'Välj en plats' }
    if (matchObj.numPlayersRequested < 1)
      invalidData = { ...invalidData, numPlayersRequested: 'Du måste söka minst en spelare' }
    if (matchObj.numPlayersRequested > 25)
      invalidData = { ...invalidData, numPlayersRequested: 'Du kan max söka 25 andra spelare' }
    if (matchObj.ranked === 'true' && !(matchObj.numPlayersRequested % 2))
      invalidData = { ...invalidData, ranked: 'Rankade matcher måste ha ett jämnt antal spelare, dvs måste du söka ett udda antal spelare.' }
    if (matchObj.sport === '') 
      invalidData = { ...invalidData, sport: 'Vänligen välj en sport' }
    if (matchObj.sport !== '' && !SPORTS.SPORTLIST.includes(matchObj.sport))
      invalidData = { ...invalidData, sport: 'Välj en sport i systemet' }
    if (isNaN(new Date(this.state.startTime)))
      invalidData = { ...invalidData, startTime: 'Välj ett giltigt datum' }

    this.setState({ invalidData: { ...this.state.invalidData, ...invalidData }})
    
    return isInvalid;
  }

  onSubmit = e => {
    e.preventDefault();

    var matchObj = { ...this.state };

    try {
      if (this.validateFields(matchObj)) throw new Error()
    }
    catch(error) {
      return;
    }

    if (matchObj.ranked === 'true') matchObj.ranked = true
    else matchObj.ranked = false

    matchObj.startTime = new Date(this.state.startTime)
    matchObj.endTime = this.state.endTime ? new Date(this.state.endTime) : new Date(new Date(this.state.startTime).setHours(new Date(this.state.startTime).getHours() + 1))
    matchObj.numPlayersRequested = parseInt(matchObj.numPlayersRequested)
    matchObj.isFull = matchObj.acceptedPlayers && matchObj.acceptedPlayers.length >= matchObj.numPlayersRequested ? true : false;

    delete matchObj.acceptedPlayers
    delete matchObj.currentDate
    delete matchObj.invalidData
    delete matchObj.maxDate
    delete matchObj.error

    this.props.firebase.createNewMatch(matchObj, this.props.profile, this.props.privateProfile)
      .then((createdMatch) => {
        createdMatch.startTimeStr = this.props.firebase.timeConverter(createdMatch.startTime / 1000)
        createdMatch.endTimeStr = this.props.firebase.timeConverter(createdMatch.endTime / 1000, false, false, createdMatch.startTimeStr)

        createdMatch.startTime = { seconds: createdMatch.startTime.valueOf() / 1000 }
        createdMatch.endTime = { seconds: createdMatch.endTime.valueOf() / 1000 }

        this.props.sendToParent(createdMatch)
      })
  }

  overrideInitialState(matchObj) {
    var stateObj = { ...matchObj }

    stateObj.startTime = this.props.firebase.timeConverter(matchObj.startTime.seconds, true, true)
    stateObj.endTime = this.props.firebase.timeConverter(matchObj.endTime.seconds, true, true)
    stateObj.ranked = matchObj.ranked ? 'true' : 'false';

    delete stateObj.author;
    delete stateObj.endTimeStr;
    delete stateObj.startTimeStr;

    this.setState({ ...stateObj })
  }

  componentDidMount() {
    if (this.props.location.state)
      this.overrideInitialState(this.props.location.state)
    else
      this.setState({ ...INITIAL_STATE })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.state !== prevProps.location.state && matchPath(this.props.location.pathname, ROUTES.NEW)) {
      if (this.props.location.state) 
        this.overrideInitialState(this.props.location.state)
      else
        this.setState({ ...INITIAL_STATE })
    }
  }

  dismount() {
    if (isMobile) this.props.history.goBack()
    else this.props.history.replace(ROUTES.CREATE)
  }

  render() {
    return (
      <Col md={isBrowser ? 4 : 12} sm={12} style={this.props.transition}
            className={`createMatchPage justify-content-center ${isMobile ? 'mobileOverlay' : 'browserSidebar'}
                      ${mobileModel} w${window.screen.width}h${window.screen.height}`}>
        <Helmet>
          <title>Spelarpoolen | Skapa match</title>
        </Helmet>

        <Col className="createMatch" xs={12}>
          <h3>{this.props.location.state ? 'Redigera match' : 'Skapa ny match'}</h3>

          <Form onSubmit={this.onSubmit}>
            <Form.Control className={`sportSelect ${this.state.invalidData.sport ? 'invalid-form' : 'valid-form'}`} 
                          name="sport" value={this.state.sport} onChange={this.onChange} 
                          as="select" style={{ backgroundImage: `url(/images/sports/${this.state.sport}.svg)` }}>
              <option value="">Sport</option>
              <optgroup label="Dina sporter">
                {this.props.profile.mySports.map((sport, k) => (
                  <option key={k} value={sport}>{SPORTS[sport].charAt(0).toUpperCase() + SPORTS[sport].slice(1)}</option>
                ))}
              </optgroup>
              <optgroup label="Övriga sporter">
              {SPORTS.SPORTLIST.map((sport, k) => {
                if (!this.props.profile.mySports.includes(sport))
                  return <option key={k} value={sport}>{SPORTS[sport].charAt(0).toUpperCase() + SPORTS[sport].slice(1)}</option>
                return null
              })}
              </optgroup>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{this.state.invalidData.sport}</Form.Control.Feedback>

            <Form.Control className={this.state.invalidData.numPlayersRequested ? 'invalid-form' : 'valid-form'}
              name="numPlayersRequested" value={this.state.numPlayersRequested} onChange={this.onChange}
              type="number" min={this.state.acceptedPlayers ? this.state.acceptedPlayers.length : 1} placeholder="Antal spelare du söker" />
            <Form.Control.Feedback type="invalid">{this.state.invalidData.numPlayersRequested}</Form.Control.Feedback>

            <Form.Control className={this.state.invalidData.city ? 'invalid-form' : 'valid-form'}
              name="city" value={this.state.city} onChange={this.onChange}
              as="select" placeholder="Stad">
              {CITIES.CITYLIST.map((city, k) => {
                if (city !== '')
                  return <option key={k} value={city}>{city}</option>
                else 
                  return <option key={k} value="" disabled>Stad</option>
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{this.state.invalidData.city}</Form.Control.Feedback>

            <Form.Control className={this.state.invalidData.location ? 'invalid-form' : 'valid-form'}
              name="location" value={this.state.location} onChange={this.onChange}
              type="text" placeholder="Plats (specifik anläggning eller område)" />
            <Form.Control.Feedback type="invalid">{this.state.invalidData.location}</Form.Control.Feedback>

            <InputGroup className={this.state.invalidData.startTime ? 'invalid-form' : 'valid-form'}>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">Starttid</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control name="startTime" value={this.state.startTime} onChange={this.onChange}
                type="datetime-local" min={`${this.state.currentDate}T00:00`} max={`${this.state.maxDate}T00:00`} />
            </InputGroup>
            <Form.Control.Feedback type="invalid">{this.state.invalidData.startTime}</Form.Control.Feedback>

            <InputGroup className={this.state.invalidData.endTime ? 'invalid-form' : 'valid-form'}>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">Sluttid</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control name="endTime" value={this.state.endTime ? this.state.endTime : [this.state.startTime && this.props.firebase.timeConverter(new Date(this.state.startTime).setHours(new Date(this.state.startTime).getHours() + 1) / 1000, true, true)]} onChange={this.onChange}
                type="datetime-local" min={this.state.startTime} max={`${this.state.maxDate}T00:00`} />
            </InputGroup>
            <Form.Control.Feedback type="invalid">{this.state.invalidData.endTime}</Form.Control.Feedback>

            <Form.Control className={this.state.invalidData.description ? 'invalid-form' : 'valid-form'}
              name="description" value={this.state.description} onChange={this.onChange}
              as="textarea" rows="3" placeholder="Beskriv din match här, t.ex. vilken nivå du önskar att spelarna ligger på." />
            <Form.Control.Feedback type="invalid">{this.state.invalidData.description}</Form.Control.Feedback>

            <Form.Row>
              <Col xs={6}>
                <Form.Check name="ranked" checked={this.state.ranked === 'true' ? true : false} onChange={this.onChange}
                  type="radio" label="Rankad match" id="true" />
                  <p className="explainer">Ger rankingpoäng</p>
              </Col>
              <Col xs={6}>
                <Form.Check name="ranked" checked={this.state.ranked === 'true' ? false : true} onChange={this.onChange}
                  type="radio" label="Vänskapsmatch" id="false" />
                  <p className="explainer">Ger EJ rankingpoäng</p>
              </Col>
              <Form.Control.Feedback type="invalid">{this.state.invalidData.ranked}</Form.Control.Feedback>
            </Form.Row>

            {this.state.error && <p className="error">{this.state.error.message}</p>}

            <Button type="submit" block>{this.props.location.state ? 'Spara ändringar' : 'Skapa match'}</Button>
          </Form>

          <Button variant="warning" block onClick={() => this.dismount()}>Avbryt</Button>
        </Col>
      </Col >
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withFirebase, withAuthorization(condition))(MatchCreationPage);