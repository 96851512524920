const config = {
  apiKey: "AIzaSyBRn_smhNCJAVh5Rx5wxUdEUDVHGUOjAu0",
  authDomain: "spelarpoolen.firebaseapp.com",
  databaseURL: "https://spelarpoolen.firebaseio.com",
  projectId: "spelarpoolen",
  storageBucket: "spelarpoolen.appspot.com",
  messagingSenderId: "770714798158",
  appId: "1:770714798158:web:055b1da1fcb9fc68"
};

export default config;