import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { isBrowser, isMobile, mobileModel } from "react-device-detect";
import { HelmetProvider } from 'react-helmet-async';

import { withAuthentication } from './components/Auth';

import Navigation from './components/Navigation/Navigation'
import NavigationMobile from './components/Navigation/NavigationMobile'
import LoginPage from './containers/LoginPage';
import LandingPage from './containers/LandingPage';
import FirstVisitPage from './containers/FirstVisitPage';
import CreateMatchPage from './containers/CreateMatchPage';
import LeaderboardPage from './containers/LeaderboardPage';
import MyMatchesPage from './containers/MyMatchesPage';
import AccountPage from './containers/AccountPage';
import HelpPage from './containers/HelpPage';
import PrivacyPolicy from './containers/PrivacyPolicy';
import TermsOfService from './containers/TermsOfService';
import NoMatch from './containers/NoMatch';
import Me from './containers/Me';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import * as ROUTES from './constants/routes';

const App = () => (
  <HelmetProvider>
    <Router>
      <Container fluid className={isBrowser ? 'colorBackground' : 'whiteBackground'}>
        <Row id="lastElementPreNavigation" className={isBrowser ? 'colorBackground' : 'whiteBackground'}>

          {isBrowser && <Navigation key="navBrowser" />}

          {!localStorage.getItem('firstVisit')
            && <Redirect to={{ pathname: ROUTES.WELCOME, state: { from: window.location.pathname } }} />
          }

          <div className={`content ${isBrowser ? 'col contentCol' : 'mobileUI'} ${mobileModel} w${window.screen.width}h${window.screen.height}`}>
            <Switch>
              {/* LoginPage */}
              <Route exact path={ROUTES.LOGIN} component={LoginPage} />

              {/* FirstVisitPage */}
              <Route exact path={ROUTES.WELCOME} component={FirstVisitPage} />

              {/* LandingPage with optional routes */}
              <Route exact path={ROUTES.LANDING} component={LandingPage} />
              <Route exact path={ROUTES.MATCHID} component={LandingPage} />
              <Route path={ROUTES.MATCH} component={LandingPage} />

              {/* CreateMatchPage and its submenues */}
              <Route exact path={ROUTES.CREATE} component={CreateMatchPage} />
              <Route exact path={ROUTES.REQUESTSID} component={CreateMatchPage} />
              <Route path={ROUTES.PREVIEW} component={CreateMatchPage} />
              <Route path={ROUTES.NEW} component={CreateMatchPage} />
              <Route path={ROUTES.REQUESTS} component={CreateMatchPage} />
              <Route path={ROUTES.RESULTS} component={CreateMatchPage} />

              {/* HighscorePage */}
              <Route path={ROUTES.LEADERBOARD} component={LeaderboardPage} />

              {/* MyMatches and its submenues */}
              <Route exact path={ROUTES.MYMATCHES} component={MyMatchesPage} />
              <Route exact path={ROUTES.MYMATCHESID} component={MyMatchesPage} />

              {/* AccountPage with "me" fallback route */}
              <Route exact path={ROUTES.PROFILEID} component={AccountPage} />
              <Route exact path={ROUTES.SPORTSELECT} component={AccountPage} />
              <Route exact path={ROUTES.PASSWORDUPDATE} component={AccountPage} />
              <Route path={ROUTES.PROFILE} component={Me} />
              <Route path={ROUTES.ME} component={Me} />

              {/* HelpPage */}
              <Route path={ROUTES.HELP} component={HelpPage} />

              {/* Privacy policy & ToS */}
              <Route path={ROUTES.PRIVACY} component={PrivacyPolicy} />
              <Route path={ROUTES.TERMS} component={TermsOfService} />

              {/* 404 - page */}
              <Route component={NoMatch} />
            </Switch>
          </div>

          {isMobile &&
            <NavigationMobile key="navMobile" />
          }

        </Row>
      </Container>
    </Router>
  </HelmetProvider>
);

export default withAuthentication(App);
