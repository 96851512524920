import React from 'react';

import Card from 'react-bootstrap/Card';
import Media from 'react-bootstrap/Media';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMapMarkerAlt, faUserFriends, faTrophy, faComment } from '@fortawesome/free-solid-svg-icons';

import './MatchCard.scss';

const MatchCardSkeleton = ({ style }) => (
  <Card style={style}>
    <Card.Header>
      <p>
        <span className="circle skeletonLoading"/>
        {/* <FontAwesomeIcon className="skeletonLoading" icon={faMapMarkerAlt} fixedWidth /> */}
        <span className="line skeletonLoading" style={{ width: '125px' }}/>
      </p>
      <p className="dateHeader">
        <span className="line skeletonLoading" style={{ width: '125px' }}/>
      </p>
    </Card.Header>
    <Card.Body className="matchOverview">
      <Media>
        <Media.Body>
          {/* Varying icon */}
          <p>
            <span className="circle skeletonLoading"/>
            {/* <FontAwesomeIcon className="skeletonLoading" icon={faTrophy} fixedWidth /> */}
            <span className="line skeletonLoading" style={{ width: '165px' }}/>
          </p>
          <p>
            <span className="circle skeletonLoading"/>
            {/* <FontAwesomeIcon className="skeletonLoading" icon={faUserFriends} fixedWidth /> */}
            <span className="line skeletonLoading" style={{ width: '90px' }}/>
          </p>
          <p>
            <span className="circle skeletonLoading"/>
            {/* <FontAwesomeIcon className="skeletonLoading" icon={faComment} fixedWidth /> */}
            <span className="line skeletonLoading" style={{ width: '250px' }}/>
          </p>
        </Media.Body>
      </Media>
    </Card.Body>
  </Card>
);

export default MatchCardSkeleton;
