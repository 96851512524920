import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { isBrowser, isMobile, mobileModel } from 'react-device-detect';

import { withFirebase } from '../Firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './PasswordUpdate.scss';

const INITIAL_STATE = {
  newPassword: '',
  newPasswordConfirm: '',
  passwordMasking: true,
  loading: false,
  error: null
};

class PasswordUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true })

    if (this.state.newPassword !== this.state.newPasswordConfirm)
      this.setState({ error: { code: 'auth/weak-password', message: 'Passwords does not match', loading: false } })

    else {
      this.props.firebase
        .updatePassword(this.state.newPassword)
        .then(() => {
          this.setState({ ...INITIAL_STATE, success: 'Ditt lösenordet är nu uppdaterat.' })
        })
        .catch(error => {
          console.log(error)
          this.setState({ error, success: null, loading: false });
        });
    }
  }

  render() {
    const isInvalid =
      this.state.newPassword === '' ||
      this.state.newPasswordConfirm === '';

    return (
      <Col md={isBrowser ? 4 : 12} sm={12} style={this.props.transition}
        className={`passwordUpdate justify-content-center ${isMobile ? 'mobileOverlay' : 'browserSidebar'}
                    ${mobileModel} w${window.screen.width}h${window.screen.height}`}>

        <Helmet>
          <title>Spelarpoolen | </title>
        </Helmet>

        <Container fluid>
          <Row>
            <h2>Uppdatera lösenord</h2>
            <Form onSubmit={this.onSubmit} autoComplete="off">

              <div className="passwordMasking">
                <FontAwesomeIcon onClick={() => this.setState(prevState => ({ passwordMasking: !prevState.passwordMasking }))}
                  icon={this.state.passwordMasking ? faEye : faEyeSlash} fixedWidth />
              </div>
              <Form.Control name="newPassword" value={this.state.newPassword} onChange={this.onChange}
                type={this.state.passwordMasking ? "password" : "passwordUnmasked"} placeholder="Nytt lösenord"/>

              <div className="passwordMasking">
                <FontAwesomeIcon onClick={() => this.setState(prevState => ({ passwordMasking: !prevState.passwordMasking }))}
                  icon={this.state.passwordMasking ? faEye : faEyeSlash} fixedWidth />
              </div>
              <Form.Control name="newPasswordConfirm" value={this.state.newPasswordConfirm} onChange={this.onChange}
                type={this.state.passwordMasking ? "password" : "passwordUnmasked"} placeholder="Bekräfta nytt lösenord" />

              {this.state.error && <p className="error">{this.state.error.message}</p>}
              {this.state.success && <p className="success">{this.state.success}</p>}

              <Button disabled={isInvalid || this.state.loading} type="submit">Uppdatera lösenord</Button>

            </Form>

            <hr/>

            <Button variant="warning" block onClick={() => this.props.hideMe()}>Gå tillbaka</Button>
          </Row>
        </Container>
      </Col>
    );
  }
}

export default withFirebase(PasswordUpdate);