import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet-async';
import { isBrowser, isMobile, mobileModel } from 'react-device-detect';

import { withFirebase } from '../Firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

import './Results.scss';

import * as ROUTES from '../../constants/routes';

class ResultSumbit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      match: this.props.location.state,
      author: this.props.location.state.author,
      teamOnePlayerIds: [this.props.location.state.author.uid, ...this.props.location.state.acceptedPlayers.map(p => p.uid)],
      teamTwoPlayerIds: [],
      teamOneWins: 'true'
    }
  }

  onSubmit = e => {
    e.preventDefault();

    const result = {
      teamOneWins: this.state.teamOneWins === 'true' 
                    ? true : this.state.teamOneWins === 'false' 
                      ? false : null,
      teamOnePlayerIds: this.state.teamOnePlayerIds,
      teamTwoPlayerIds: this.state.teamTwoPlayerIds,
      sport: this.state.match.sport
    }

    this.props.firebase.setMatchResult(this.state.match.id, result).then(() => {
      this.props.sendToParent(this.state.match.id)
    })
  }

  swapTeams = (uid) => {
    if (this.state.teamOnePlayerIds.includes(uid)) {
      this.setState(prevState => ({
        teamOnePlayerIds: prevState.teamOnePlayerIds.filter(player => player !== uid),
        teamTwoPlayerIds: prevState.teamTwoPlayerIds.concat(uid)
      }));
    } else {
      this.setState(prevState => ({
        teamOnePlayerIds: prevState.teamOnePlayerIds.concat(uid),
        teamTwoPlayerIds: prevState.teamTwoPlayerIds.filter(player => player !== uid)
      }));
    }
  }

  dismount() {
    if (isMobile) this.props.history.goBack()
    else this.props.history.replace(ROUTES.CREATE)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.state && this.props.location.state !== prevProps.location.state)
      this.setState({ match: this.props.location.state, author: this.props.location.state.author })
  }

  render() {
    var match = this.state.match;

    return (
      <Col md={isBrowser ? 4 : 12} sm={12} style={this.props.transition}
        className={`resultPage justify-content-center ${isMobile ? 'mobileOverlay' : 'browserSidebar'}
                      ${mobileModel} w${window.screen.width}h${window.screen.height}`}>
        <Helmet>
          <title>Spelarpoolen | Rapportera resultat</title>
        </Helmet>

        <Container fluid>
          <Row>
            <h5>Rapportera resultat</h5>
          </Row>

          {match.acceptedPlayers.length > 0 
            ? <div className="acceptedPlayers">
                <Row className="teamHeaders">
                  <Col xs={6}>
                    <p className="teamOne">Lag 1</p>
                  </Col>
                  <Col xs={6}>
                    <p className="teamTwo">Lag 2</p>
                  </Col>
                </Row>
                <Row>
                  {!this.state.teamOnePlayerIds.includes(match.author.uid) && 
                    <Col xs={6} className="teamSwapper" onClick={() => this.swapTeams(match.author.uid)}>
                      <FontAwesomeIcon icon={faChevronCircleRight} rotation={180} />
                    </Col>
                  }
                  <Col xs={6}>
                    <Media className={this.state.teamOnePlayerIds.includes(match.author.uid) ? 'teamOne' : 'teamTwo'}>
                      <Media.Body>
                        <p>{match.author.fullname}<br />
                          Rank: {Math.round(match.author.ranking)}</p>
                      </Media.Body>
                      <Image className="align-self-center mr-3" roundedCircle width={24} height={24} src={match.author.profilePicture} alt="Profile placeholder" />
                    </Media>
                  </Col>
                  {this.state.teamOnePlayerIds.includes(match.author.uid) &&
                    <Col xs={6} className="teamSwapper" onClick={() => this.swapTeams(match.author.uid)}>
                      <FontAwesomeIcon icon={faChevronCircleRight} />
                    </Col>
                  }
                </Row>
                {match.acceptedPlayers.map((player, k) => (
                  <Row key={k}>
                    {!this.state.teamOnePlayerIds.includes(player.uid) && 
                      <Col xs={6} className="teamSwapper" onClick={() => this.swapTeams(player.uid)}>
                        <FontAwesomeIcon icon={faChevronCircleRight} rotation={180} />
                      </Col>
                    }
                    <Col xs={6}>
                      <Media className={this.state.teamOnePlayerIds.includes(player.uid) ? 'teamOne' : 'teamTwo'}>
                        <Media.Body>
                          <p>{player.fullname}<br />
                            Rank: {Math.round(player.ranking)}</p>
                        </Media.Body>
                        <Image className="align-self-center mr-3" roundedCircle width={24} height={24} src={player.profilePicture} alt="Profile placeholder" />
                      </Media>
                    </Col>
                    {this.state.teamOnePlayerIds.includes(player.uid) && 
                      <Col xs={6} className="teamSwapper" onClick={() => this.swapTeams(player.uid)}>
                        <FontAwesomeIcon icon={faChevronCircleRight} />
                      </Col>
                    }
                  </Row>
                ))}
                {this.state.teamOnePlayerIds.length !== this.state.teamTwoPlayerIds.length &&
                  <p className="error">Lagen är ej balancerade</p>
                }
              </div>
            : <div className="acceptedPlayers">
                <Row>
                  Finns inga spelare antagna, godkänn någons ansökan och kom tillbaka senare.
                </Row>
              </div>
          }

          <hr />

          <Row>
            {match.acceptedPlayers.length > 0 &&
              <Form onSubmit={this.onSubmit} disabled={false}>
                <Form.Label>
                  Vilket lag vann?
                </Form.Label>
                <Row className="d-flex flex-column">
                  <ButtonGroup>
                    <Button variant={this.state.teamOneWins === 'true' ? 'secondary' : 'outline-secondary'} onClick={() => this.setState({ teamOneWins: 'true' })}>
                      Lag 1
                    </Button>
                    <Button variant={this.state.teamOneWins === 'null' ? 'secondary' : 'outline-secondary'} onClick={() => this.setState({ teamOneWins: 'null' })}>
                      Oavgjort
                    </Button>
                    <Button variant={this.state.teamOneWins === 'false' ? 'secondary' : 'outline-secondary'} onClick={() => this.setState({ teamOneWins: 'false' })}>
                      Lag 2
                    </Button>
                  </ButtonGroup>
                </Row>
                <Button variant="primary" type="submit" block disabled={this.state.teamOnePlayerIds.length !== this.state.teamTwoPlayerIds.length}>
                  Rapportera resultat
                </Button>
              </Form>
            }
            
            <Button variant="warning" block onClick={() => this.dismount()}>Gå tillbaka</Button>
          </Row>
        </Container>
      </Col>
    );
  }
}

export default compose(withFirebase, withRouter)(ResultSumbit);