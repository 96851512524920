import React, { Component } from 'react';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet-async';
import { isBrowser, isMobile, mobileModel } from 'react-device-detect';

import { withFirebase } from '../Firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faAt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookMessenger, faFacebook } from '@fortawesome/free-brands-svg-icons';

import Report from '../Report/Report';
import ProfilePlaceholder from '../../constants/ProfilePlaceholder.png';

import './Match.scss';

import * as ROUTES from '../../constants/routes';
import * as SPORTS from '../../constants/sports';

class MatchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      match: null,
      author: null,
      message: '',
      loading: true
    }
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.firebase.createMatchRequest(this.state.match, this.state.author.uid, this.state.message, this.props.profile, this.props.privateProfile)
      .then(() => {
        this.setState({ message: '' });
        this.props.history.push(ROUTES.LANDING);
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ match: this.props.location.state, author: this.props.location.state.author, loading: false })

      if (this.props.profile)
        this.props.firebase.getRequestByYou(this.props.location.state.id).then(request => {
          this.setState({ request })
        })

      if (this.props.firebase.auth.currentUser && (
        (this.props.location.state.author.uid === this.props.firebase.auth.currentUser.uid) ||
        (this.props.location.state.acceptedPlayerIDs.includes(this.props.firebase.auth.currentUser.uid))))
        this.props.firebase.getPrivateInfo(this.props.location.state.id).then(privateInfo => { this.setState({ privateInfo }) })
    }
    else {
      var matchId = this.props.match.params.matchId
      if (isMobile && this.props.history.action === 'POP') {
        this.props.history.replace(ROUTES.MATCH)
        this.props.history.push(`${ROUTES.MATCH}/${matchId}`)
      }
      this.props.firebase.getMatchById(matchId)
        .then(match => {
          this.setState({ match, author: match.author, loading: false })

          if (this.props.firebase.auth.currentUser && (
            (match.author.uid === this.props.firebase.auth.currentUser.uid) ||
            (match.acceptedPlayerIDs.includes(this.props.firebase.auth.currentUser.uid))))
            this.props.firebase.getPrivateInfo(matchId).then(privateInfo => { this.setState({ privateInfo }) })
        })

      if (this.props.profile)
        this.props.firebase.getRequestByYou(matchId).then(request => {
          this.setState({ request })
        })
    }
  }

  componentDidUpdate(prevProps) {
    let path = this.props.location.pathname;
    if (this.props.location.state && this.props.location.state !== prevProps.location.state
      && (matchPath(path, ROUTES.MATCHID) || matchPath(path, ROUTES.MYMATCHESID) || matchPath(path, ROUTES.PREVIEW))) {

      this.setState({ match: this.props.location.state, author: this.props.location.state.author, request: false })

      if (this.props.firebase.auth.currentUser && (
        (this.props.location.state.author.uid === this.props.firebase.auth.currentUser.uid) ||
        (this.props.location.state.acceptedPlayerIDs.includes(this.props.firebase.auth.currentUser.uid))))
        this.props.firebase.getPrivateInfo(this.props.location.state.id).then(privateInfo => { this.setState({ privateInfo, loading: false }) })
      else
        this.setState({ loading: false })
    }
  }

  dismount() {
    if (isMobile) this.props.history.goBack()
    else {
      if (matchPath(this.props.location.pathname, ROUTES.PREVIEW) || matchPath(this.props.location.pathname, ROUTES.NEW) || matchPath(this.props.location.pathname, ROUTES.REQUESTS))
        this.props.history.replace(ROUTES.CREATE)
      else if (matchPath(this.props.location.pathname, ROUTES.MATCHID))
        this.props.history.replace(ROUTES.LANDING)
      else
        this.props.history.replace(ROUTES.MYMATCHES)
    }
  }

  render() {
    let match = this.state.match
    let rankings, sumRankings, avgRanking
    if (match) {
      rankings = [match.author.ranking]
      match.acceptedPlayers.forEach(player => {
        rankings.push(player.ranking)
      })
      sumRankings = rankings.reduce((previous, current) => current += previous);
      avgRanking = Math.round(sumRankings / rankings.length);
    }

    let disableRequests =
      (!this.props.profile && ` `) ||
      (match && match.isFull && '\nMatchen är full.') ||
      (match && match.acceptedPlayers.map(ap => ap.uid).includes(this.props.profile.uid) && '\nDu är redan med i matchen.') ||
      (this.props.profile && this.state.request && this.state.request.denied && '\nDin ansökan till matchen har blivit nekad.') ||
      (this.props.profile && this.state.request && '\nDu har redan ansökt till denna match.') ||
      (this.state.author && this.state.author.uid === this.props.profile.uid && '\nDu är skaparen till denna match.');

    return (
      <Col md={isBrowser ? 4 : 12} sm={12} style={this.props.transition}
        className={`matchPage justify-content-center ${isMobile ? 'mobileOverlay' : 'browserSidebar'}
                      ${mobileModel} w${window.screen.width}h${window.screen.height}`}>
        <Helmet>
          <title>Spelarpoolen | Match</title>
          <meta name="title" content="Hitta motståndare i din sport. Till dig eller ditt lag eller klubb." />
          <meta name="description" content="Se matcher där folk söker spelare i sporter som padel, tennis, badminton mm. Sök till vänskapsmatcher, tävlingsmatcher, korpen och korpenlag. " />
        </Helmet>

        {!this.state.loading &&
          <Container fluid>
            <Row>
              <Media>
                <Media.Body>
                  <h5>Spela {SPORTS[match.sport]} med <br /> {this.state.author.fullname}</h5>
                  <p>Datum: {match.startTimeStr} - {match.endTimeStr} <br />
                    Plats: {match.city}, {match.location} <br />
                    Medelranking: {avgRanking} <br />
                    {match.acceptedPlayers.length > 0 && 'Antagna spelare:'}</p>
                </Media.Body>
                {this.state.author.profilePicture
                  ? <Image roundedCircle width={80} height={80} src={this.state.author.profilePicture} alt="Profile placeholder" />
                  : <Image roundedCircle width={80} height={80} src={ProfilePlaceholder} alt="Generic placeholder" />
                }
              </Media>
              {match.acceptedPlayers.length > 0 &&
                <div style={{ width: '100%' }}>
                  <Row className="acceptedPlayers">
                    <Col xs={6}>
                      <Media>
                        <Media.Body>
                          <p>{match.author.fullname}<br />
                          Rank: {Math.round(match.author.ranking)}</p>
                        </Media.Body>
                        {match.author.profilePicture
                          ? <Image className="align-self-center mr-3" roundedCircle width={24} height={24} src={match.author.profilePicture} alt="Profile placeholder" />
                          : <Image className="align-self-center mr-3" roundedCircle width={24} height={24} src={ProfilePlaceholder} alt="Profile placeholder" />
                        }
                      </Media>
                    </Col>
                    {match.acceptedPlayers.map((player, k) => (
                      <Col xs={6} key={k}>
                        <Media>
                          <Media.Body>
                            <p>{player.fullname}<br />
                            Rank: {Math.round(player.ranking)}</p>
                          </Media.Body>
                          {player.profilePicture
                            ? <Image className="align-self-center mr-3" roundedCircle width={24} height={24} src={player.profilePicture} alt="Profile placeholder" />
                            : <Image className="align-self-center mr-3" roundedCircle width={24} height={24} src={ProfilePlaceholder} alt="Profile placeholder" />
                          }
                        </Media>
                      </Col>
                    ))}
                  </Row>
                </div>
              }
            </Row>

            <Row>
              <p style={{ marginBottom: '10px' }}>Beskrivning: {match.description}</p>
            </Row>

            <Row>
              {this.state.privateInfo &&
                <Button block onClick={() => window.location.href = `mailto:${Object.keys(this.state.privateInfo).map(uid => uid !== this.props.firebase.auth.currentUser.uid ? this.state.privateInfo[uid].email : null).filter(email => { return email !== null }).join(';')}`}>
                  Maila spelarna i matchen
              </Button>
              }
            </Row>

            <Row className="buttonRow">
              <Col xs={6}>
                <Dropdown>
                  <Dropdown.Toggle variant="dark">
                    <FontAwesomeIcon icon={faShare} fixedWidth/>&emsp;Dela match
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {isMobile &&
                      <Dropdown.Item onClick={() => window.open(`fb-messenger://share?link=${'https://spelarpoolen.se'}${ROUTES.MATCH}/${this.state.match.id}&app_id=486643288835869`)}>
                        <FontAwesomeIcon icon={faFacebookMessenger} fixedWidth/>&emsp;Messenger
                      </Dropdown.Item>
                    }
                    <Dropdown.Item onClick={() => window.open(`https://www.facebook.com/dialog/share?app_id=486643288835869&display=popup&link=${'https://spelarpoolen.se'}${ROUTES.MATCH}/${this.state.match.id}&redirect=${'https://spelarpoolen.se'}${ROUTES.MATCH}/${this.state.match.id}&href=${'https://spelarpoolen.se'}${ROUTES.MATCH}/${this.state.match.id}`, '', 'height=500,width=500')}>
                      <FontAwesomeIcon icon={faFacebook} fixedWidth/>&emsp;{isMobile ? 'Facebook' : 'FB / Messenger'}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => window.open(`mailto:?subject=Spela ${SPORTS[this.state.match.sport]}&body=Länk till matchen: ${'https://spelarpoolen.se'}${ROUTES.MATCH}/${this.state.match.id}`)}>
                      <FontAwesomeIcon icon={faAt} fixedWidth/>&emsp;Maila
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xs={6}>
                <Report match={match} profile={this.props.profile} user={this.state.author} />
              </Col>
            </Row>

            <hr />

            <Row>
              <Form onSubmit={this.onSubmit} disabled={disableRequests}>
                <Form.Label>
                  Ansök om att spela
                </Form.Label>
                {!this.props.profile
                  ? <div className="textarea">
                    <p><Link to={ROUTES.LOGIN}>Logga in</Link> för att ansöka om matcher</p>
                  </div>
                  : <Form.Control name="message" value={disableRequests ? disableRequests : this.state.message} onChange={this.onChange}
                    as="textarea" rows="3" disabled={disableRequests} style={{ textAlign: disableRequests && 'center' }} />
                }
                <Button variant="primary" type="submit" block disabled={disableRequests}>
                  Skicka ansökan
                </Button>
              </Form>

              <Button variant="warning" block onClick={() => this.dismount()}>Gå tillbaka</Button>
            </Row>
          </Container>
        }
      </Col>
    );
  }
}

export default compose(withFirebase, withRouter)(MatchPage);