import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  firstname: '',
  lastname: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  passwordMasking: true,
  termsOfService: false,
  error: null,
};

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = e => {
    e.preventDefault();

    if (this.state.passwordOne !== this.state.passwordTwo)
      this.setState({ error: { code: 'auth/weak-password', message: 'Passwords does not match' } })

    if (!this.state.termsOfService)
      this.setState({ error: { code: 'auth/tos-unchecked', message: 'You must agree to the terms of service.' } })

    else {
      this.props.firebase
        .createUserWithEmailAndPassword(this.state.email, this.state.passwordOne, this.state.firstname, this.state.lastname)
        .then(() => {
          if (this.props.from) this.props.history.replace(this.props.from);
          else this.props.history.replace(ROUTES.LANDING);
        })
        .catch(error => {
          console.log(error)
          this.setState({ error });
        });
    }
  }

  onChange = e => {
    if (e.target.type === 'checkbox')
      this.setState({ [e.target.name]: e.target.checked })
    else
      this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const isInvalid =
      this.state.email === '' ||
      this.state.firstname === '' ||
      this.state.lastname === '' ||
      this.state.passwordOne === '' ||
      this.state.passwordTwo === '' ||
      this.state.termsOfService === false;

    return (
      <div>
        <h3>Skapa nytt konto</h3>
        <Form onSubmit={this.onSubmit} autoComplete="off">

          <Form.Row>
            <Col xs={6}>
              <Form.Control name="firstname" value={this.state.firstname} onChange={this.onChange}
                type="text" placeholder="Förnamn" />
            </Col>

            <Col xs={6}>
              <Form.Control name="lastname" value={this.state.lastname} onChange={this.onChange}
                type="text" placeholder="Efternamn" />
            </Col>
          </Form.Row>

          <Form.Control name="email" value={this.state.email} onChange={this.onChange}
            type="text" placeholder="Mailadress" />

          <div className="passwordMasking">
            <FontAwesomeIcon onClick={() => this.setState(prevState => ({ passwordMasking: !prevState.passwordMasking }))}
              icon={this.state.passwordMasking ? faEye : faEyeSlash} fixedWidth />
          </div>
          <Form.Control name="passwordOne" value={this.state.passwordOne} onChange={this.onChange}
            type={this.state.passwordMasking ? "password" : "passwordUnmasked"} placeholder="Lösenord" />

          <div className="passwordMasking">
            <FontAwesomeIcon onClick={() => this.setState(prevState => ({ passwordMasking: !prevState.passwordMasking }))}
              icon={this.state.passwordMasking ? faEye : faEyeSlash} fixedWidth />
          </div>
          <Form.Control name="passwordTwo" value={this.state.passwordTwo} onChange={this.onChange}
            type={this.state.passwordMasking ? "password" : "passwordUnmasked"} placeholder="Bekräfta lösenord" />

          <Form.Check name="termsOfService" onChange={this.onChange} type="checkbox" style={{ display: 'inline-block', marginRight: '7px' }} />
          <Form.Label>Jag godkänner <a href={ROUTES.TERMS} target="_blank" rel="noopener noreferrer">användarvillkoren</a>.</Form.Label>
              
          <Button disabled={isInvalid} type="submit">Skapa konto</Button>

          {this.state.error && <p className="error">{this.state.error.message}</p>}
        </Form>

        <a href='/login' onClick={(e) => this.props.createNewUser(e, false)}>Tillbaka</a>
      </div>
    );
  }
}

export default compose(withRouter,withFirebase)(SignUp);