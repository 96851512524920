import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withAuthorization, AuthUserContext } from '../components/Auth';

import * as ROUTES from '../constants/routes';

class AccountPage extends Component {

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => authUser 
          ? <Redirect to={`${ROUTES.PROFILE}/${authUser.uid}`}/>
          : <p>Redirecting...</p>
        }
      </AuthUserContext.Consumer>
  )}
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);