import React, { Component } from 'react';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { AuthUserContext } from '../Auth';
import { mobileModel } from 'react-device-detect';

import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faAward, faTableTennis } from '@fortawesome/free-solid-svg-icons';
import { faCalendarPlus, faUserCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import './NavigationMobile.scss';

import * as ROUTES from '../../constants/routes';

class NavigationMobile extends Component {
	activeRoute = (ROUTELIST) => {
		var path = this.props.location.pathname;

		if (!!matchPath(path, ROUTES.LOGIN) && matchPath(path, ROUTES.LOGIN).isExact)
			path = this.props.location.state && this.props.location.state.from ? this.props.location.state.from : path;

		var flag = 'notActive'
		ROUTELIST.map(ROUTE => {
			if (!!matchPath(path, ROUTE) && matchPath(path, ROUTE).isExact)
				return flag = 'active';
			return flag;
		})
		return flag;
	}

	render() {
		return (
			<Navbar className={`NavigationMobile ${mobileModel} w${window.screen.width}h${window.screen.height}`}
				style={{ display: (!!matchPath(window.location.pathname, ROUTES.WELCOME) && matchPath(window.location.pathname, ROUTES.WELCOME).isExact) ? 'none' : 'block'}}
				fixed="bottom" bg="light" variant="light">
				<Container fluid>
					<Col xs={2}>
						<Link to={ROUTES.LANDING} className={this.activeRoute([ROUTES.LANDING, ROUTES.MATCH, ROUTES.MATCHID])}>
							<FontAwesomeIcon size="2x" icon={faSearch} fixedWidth />
							<p>Hitta match</p>
						</Link>
					</Col>
					<Col xs={2}>
						<Link to={ROUTES.CREATE} className={this.activeRoute([ROUTES.CREATE, ROUTES.PREVIEW, ROUTES.NEW, ROUTES.REQUESTS, ROUTES.RESULTS])}>
							<FontAwesomeIcon size="2x" icon={faCalendarPlus} fixedWidth />
							<p>Skapa match</p>
						</Link>
					</Col>
					<Col xs={2}>
						<Link to={ROUTES.LEADERBOARD} className={this.activeRoute([ROUTES.LEADERBOARD])}>
							<FontAwesomeIcon size="2x" icon={faAward} fixedWidth />
							<p>Topplistan</p>
						</Link>
					</Col>
					<Col xs={2}>
						<Link to={ROUTES.MYMATCHES} className={this.activeRoute([ROUTES.MYMATCHES, ROUTES.MYMATCHESID])}>
							<FontAwesomeIcon size="2x" icon={faTableTennis} fixedWidth />
							<p>Mina matcher</p>
						</Link>
					</Col>
					<Col xs={2}>
						<AuthUserContext.Consumer>
							{authUser => authUser
								? <Link to={`${ROUTES.PROFILE}/${authUser.uid}`} className={this.activeRoute([ROUTES.PROFILEID, ROUTES.SPORTSELECT])}>
										<FontAwesomeIcon size="2x" icon={faUserCircle} fixedWidth />
										<p>Min profil</p>
									</Link>
								: <Link to={ROUTES.ME} className={this.activeRoute([ROUTES.ME])}>
										<FontAwesomeIcon size="2x" icon={faUserCircle} fixedWidth />
										<p>Min profil</p>
									</Link>
							}
						</AuthUserContext.Consumer>
					</Col>
					<Col xs={2}>
						<Link to={ROUTES.HELP} className={this.activeRoute([ROUTES.HELP])}>
							<FontAwesomeIcon size="2x" icon={faQuestionCircle} fixedWidth />
							<p>Hjälp</p>
						</Link>
					</Col>
				</Container>

			</Navbar>
		)
	}
};

export default withRouter(NavigationMobile);