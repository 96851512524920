import React, { Component } from 'react';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet-async';
import { isBrowser, isMobile } from 'react-device-detect';
import { Transition } from 'react-spring/renderprops';

import { withFirebase } from '../components/Firebase';
import { withRouter } from 'react-router-dom';
import { withAuthorization } from '../components/Auth';

import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Header from '../components/Header/Header';
import Match from '../components/Match/Match';
import MatchCard from '../components/MatchCard/MatchCard';
import MatchCardSkeleton from '../components/MatchCard/MatchCardSkeleton';
import CreateMatch from '../components/CreateMatch/CreateMatch';
import RequestHandeler from '../components/RequestHandeler/RequestHandeler';
import ResultSumbit from '../components/Results/Results';

import * as SPORTS from '../constants/sports';
import * as ROUTES from '../constants/routes';

import '../styles/CreateMatchPage.scss';

class CreateMatchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newMatch: false,
      handleRequests: false,
      previewMatch: false,
      submitResults: false,
      sidebarWasShowing: false,
      sidebarWillShow: false,
      matchId: '',
      matches: [],
      matchRequests: {},
      resultsSubmitted: [],
      deletedMatchIDs: [],
      currentDate: this.props.firebase.timeConverter(Date.now() / 1000, true),
      loading: true,
      loadingPrivate: true
    }
  }

  addProfileListener = async () => {
    var userId = this.props.firebase.auth.currentUser.uid;

    this.profileListener = this.props.firebase.getProfileListener(userId)
      .onSnapshot(snapshot => {
        if (this.mounted) {
          var profile = snapshot.data();
          profile.uid = userId;

          this.setState({ profile })
        }
      })

    this.privateListener = this.props.firebase.getPrivateListener(userId)
      .onSnapshot(snapshot => {
        var privateProfile = snapshot.data();

        this.setState({ privateProfile, loadingPrivate: false })
      })

    return [this.profileListener, this.privateListener]
  }
  
  addNewMatch = matchObj => {
    var matches = this.state.matches
    var i = matches.findIndex(x => x.id === matchObj.id);

    if (i < 0) matches.push(matchObj)
    else {
      matchObj.acceptedPlayers = matches[i].acceptedPlayers
      matches[i] = matchObj
    }
    this.setState({ matches })
    this.props.history.replace(ROUTES.CREATE)
  }

  addAcceptedPlayerToMatch = requestObj => {
    var loadedMatches = this.state.matches;
    var loadedMatchRequests = this.state.matchRequests[this.state.matchId];
    
    loadedMatches.map(match => {
      if (match.id === this.state.matchId) {
        if (match.acceptedPlayerIDs && match.acceptedPlayerIDs.length !== 0) {
          match.acceptedPlayerIDs.push(requestObj.requestee.uid)
          match.acceptedPlayers.push(requestObj.requestee) 
        } else {
          match.acceptedPlayerIDs = [requestObj.requestee.uid]
          match.acceptedPlayers = [requestObj.requestee]
        }
      }
      return match;
    })

    loadedMatchRequests = loadedMatchRequests.filter(reqObj => { return reqObj.id !== requestObj.requestee.uid })

    this.setState(prevState => ({ matches: loadedMatches, matchRequests: { ...prevState.matchRequests, [this.state.matchId]: loadedMatchRequests } }))
  }

  deniedPlayerRequest = requestObj => {
    var loadedMatchRequests = this.state.matchRequests[this.state.matchId];

    loadedMatchRequests = loadedMatchRequests.filter(reqObj => { return reqObj.id !== requestObj.requestee.uid })
    this.setState(prevState => ({ matchRequests: { ...prevState.matchRequests, [this.state.matchId]: loadedMatchRequests } }))
  }

  addResultToMatch = matchId => {
    if (isMobile) this.props.history.goBack()
    else this.props.history.replace(ROUTES.CREATE)

    this.setState(prevState => ({ resultsSubmitted: [...prevState.resultsSubmitted, matchId] }))
  }
  
  createNewMatch = () => {
    if (isMobile) this.props.history.push(ROUTES.NEW)
    else this.props.history.replace(ROUTES.NEW)
    this.setState({ matchId: '' })
  }

  deletedMatch = matchId => {
    this.setState(prevState => ({ deletedMatchIDs: [...prevState.deletedMatchIDs, matchId] }))
  }

  componentDidMount() {
    this.mounted = true;
    this.props.firebase.resetPagination();
    var pListener = this.addProfileListener();

    var updateMatches = this.props.firebase.getMatchesByParams(null, null, null, null, null, true, null)
      .then(response => {
        if (this.mounted)
          this.setState({ matches: response })

        response.forEach(match => {
          return this.props.firebase.getMatchRequests(match.id).then(requests => {
            if (this.mounted)
              this.setState(prevState => ({ matchRequests: { ...prevState.matchRequests, [match.id]: requests } }));
          })
        })
      })

    Promise.all([pListener, updateMatches]).then(() => { this.setState({ loading: false })})
  }

  componentWillUnmount() {
    this.profileListener();
    this.privateListener();
    this.mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sidebarWasShowing === true && !(prevState.newMatch || prevState.handleRequests || prevState.previewMatch || prevState.submitResults))
      this.setState({ sidebarWasShowing: false })
    else if (prevState.sidebarWasShowing === false && (prevState.newMatch || prevState.handleRequests || prevState.previewMatch || prevState.submitResults))
      this.setState({ sidebarWasShowing: true })
      
    if (this.props.location.state && prevState.matchId !== this.props.location.state.id)
      this.setState({ matchId: this.props.location.state.id })

    if (prevState.previewMatch === false && this.props.location.pathname === `${ROUTES.PREVIEW}`)
      this.setState({ newMatch: false, handleRequests: false, previewMatch: true, submitResults: false, sidebarWillShow: true })
    else if (prevState.handleRequests === false && this.props.location.pathname === `${ROUTES.REQUESTS}`)
      this.setState({ newMatch: false, handleRequests: true, previewMatch: false, submitResults: false, sidebarWillShow: true })
    else if (prevState.submitResults === false && this.props.location.pathname === `${ROUTES.RESULTS}`)
      this.setState({ newMatch: false, handleRequests: false, previewMatch: false, submitResults: true, sidebarWillShow: true })
    else if (prevState.newMatch === false && this.props.location.pathname === `${ROUTES.NEW}` && 
      ((!this.state.loading && !(this.state.matches.length > 4)) || this.props.location.state))
      this.setState({ newMatch: true, handleRequests: false, previewMatch: false, submitResults: false, sidebarWillShow: true }) 
    else if ((prevState.previewMatch === true && this.props.location.pathname !== `${ROUTES.PREVIEW}`) ||
        (prevState.handleRequests === true && this.props.location.pathname !== `${ROUTES.REQUESTS}`) ||
        (prevState.submitResults === true && this.props.location.pathname !== `${ROUTES.RESULTS}`) ||
        (prevState.newMatch === true && this.props.location.pathname !== `${ROUTES.NEW}`))
      this.setState({ previewMatch: false, handleRequests: false, newMatch: false, submitResults: false, sidebarWillShow: false, matchId: '' })
  }

  render() {
    let firstMatchGotRequests = false;
    let firstMatchFound = false;

    if (!this.state.loading && this.state.matches && this.state.matches.length > 0) {
      this.state.matches.forEach(match => {
        if (this.state.deletedMatchIDs.includes(match.id)) return
        else if (!firstMatchFound) {
          firstMatchFound = true;
          if (this.state.matchRequests[match.id] && this.state.matchRequests[match.id].length > 0)
            firstMatchGotRequests = true;
        }
      })
    }

    return (
      <div className="CreateMatchPage">
        <Helmet>
          <title>Spelarpoolen | Skapade matcher</title>
          <meta name="title" content="Skapa match och hitta motståndare i sport."/>
          <meta name="description" content={`Letar du spelare till din match? Lägg upp ditt event/match med tid och datum och andra användare kan söka om att få spela med dig. Skapa rankingmatch eller vänskapsmatch. ${SPORTS.SPORTLIST.map(sport => ' ' + SPORTS[sport]).toString()}`} />
        </Helmet>

        <Col className="createMatchContent" md={(this.state.newMatch || this.state.handleRequests || this.state.previewMatch || this.state.submitResults) && isBrowser ? 8 : 12} sm={12}>
          <Header title="Skapa match" desc="Dina egna skapade matcher" 
                  loading={this.state.loading} profile={this.state.profile} history={this.props.history} />

          <div id="matchList" className={isBrowser ? 'browserMode' : 'mobileMode'}
              style={{ paddingTop: firstMatchGotRequests ? '10px' : '0px' }}>
            {this.state.loading
              ? <div className="loadingIndication">
                  <MatchCardSkeleton/>
                  <MatchCardSkeleton style={{ opacity: 0.7 }}/>
                  <MatchCardSkeleton style={{ opacity: 0.4 }}/>
                  <MatchCardSkeleton style={{ opacity: 0.2 }}/>
                </div>
              : this.state.matches && this.state.matches.length > 0
                ? this.state.matches.map((match, k) => (
                    <MatchCard key={k} matchObj={match} requests={this.state.matchRequests[match.id]} selected={this.state.matchId === match.id} resultSubmitted={this.state.resultsSubmitted.includes(match.id)} deleteToParent={this.deletedMatch} />
                  ))
                : <div className="noResults">
                    <br/>
                    <h4>Du är inte ägare för några matcher just nu</h4>
                    <br/>
                    <p>Skapa en ny match genom att trycka på knappen nedan</p>
                  </div>
            }
          </div>
          {!this.state.matches || this.state.matches.length < 5
            ? <Button onClick={this.createNewMatch} block disabled={this.state.loading}>Skapa ny match</Button>
            : <Button variant="warning" block disabled>Du kan ej skapa fler än 5 matcher</Button>
          }
        </Col>

        <Transition
          items={this.state.previewMatch}
          // initial={this.props.history.action === "POP" ? null : undefined}
          from={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                  opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWasShowing ? '0' : '1',
                  position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
          enter={{ transform: 'translate3d(0%, 0, 0)', opacity: '1', position: isBrowser && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
          leave={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                  opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWillShow ? '0' : '1',
                  position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWillShow ? 'relative' : 'absolute', right: 0 }}>
          {show => show &&
            (props => 
              <Match profile={this.state.profile} transition={props} /> )
          }
        </Transition>

        <Transition
          items={this.state.newMatch}
          // initial={this.props.history.action === "POP" ? null : undefined}
          from={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                  opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWasShowing ? '0' : '1',
                  position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
          enter={{ transform: 'translate3d(0%, 0, 0)', opacity: '1', position: isBrowser && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
          leave={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                  opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWillShow ? '0' : '1',
                  position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWillShow ? 'relative' : 'absolute', right: 0 }}>
          {show => show &&
            (props => 
              <CreateMatch profile={this.state.profile} privateProfile={this.state.privateProfile} sendToParent={this.addNewMatch} transition={props} /> 
          )}
        </Transition>

        <Transition
          items={this.state.handleRequests}
          // initial={this.props.history.action === "POP" ? null : undefined}
          from={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                  opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWasShowing ? '0' : '1',
                  position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
          enter={{ transform: 'translate3d(0%, 0, 0)', opacity: '1', position: isBrowser && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
          leave={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                  opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWillShow ? '0' : '1',
                  position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWillShow ? 'relative' : 'absolute', right: 0 }}>
          {show => show &&
            (props => 
              <RequestHandeler profile={this.state.profile} requests={this.state.matchRequests[this.state.matchId]} acceptedPlayerToParent={this.addAcceptedPlayerToMatch} deniedPlayerToParent={this.deniedPlayerRequest} transition={props} /> 
          )}
        </Transition>

        <Transition
          items={this.state.submitResults}
          // initial={this.props.history.action === "POP" ? null : undefined}
          from={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                  opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWasShowing ? '0' : '1',
                  position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
          enter={{ transform: 'translate3d(0%, 0, 0)', opacity: '1', position: isBrowser && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
          leave={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                  opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWillShow ? '0' : '1',
                  position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWillShow ? 'relative' : 'absolute', right: 0 }}>
          {show => show &&
            (props => 
              <ResultSumbit profile={this.state.profile} sendToParent={this.addResultToMatch} transition={props} /> 
          )}
        </Transition>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withFirebase, withRouter, withAuthorization(condition))(CreateMatchPage);