import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import ReactCrop from 'react-image-crop'
import { withFirebase } from '../Firebase';
import { isMobile } from 'react-device-detect';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCloudUploadAlt, faTrashAlt, faImage } from '@fortawesome/free-solid-svg-icons';

import ProfilePlaceholder from '../../constants/ProfilePlaceholder.png';
import { base64StringtoFile, extractImageFileExtensionFromBase64, image64toCanvasRef } from './Helper';

import 'react-image-crop/lib/ReactCrop.scss';
import './ImageUpload.scss';

const INITIAL_STATE = {
  imgSrc: null,
  imgSrcExt: null,
  showModal: false,
  crop: {
    aspect: 1/1
  }
};

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.imageCanvasRef = React.createRef()
    this.dropzoneSettings = {
      maxSize: 10000000, // 10 MB
      acceptedFiles: ['image/jpeg', 'image/jpg', 'image/png', 'image/x-png']
    }
    this.state = { 
      ...INITIAL_STATE,
      editableProfile: this.props.editable
    }
  }

  handleOnDrop = (files, rejectedFiles = null) => {
    if (files && files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {

        this.setState({ 
          imgSrc: reader.result,
          imgSrcExt: extractImageFileExtensionFromBase64(reader.result)
        })
      }, false)

      reader.readAsDataURL(files[0])
    }

    //Log and alert invalid files
    if (rejectedFiles && rejectedFiles.length > 0) {
      console.log('rejected file(s)', rejectedFiles)
      var badFile = rejectedFiles[0];

      if (badFile.size > this.dropzoneSettings.maxSize)
        alert('Otillåten fil. Ladda upp en fil mindre än 10 Mb.')
      if (!this.dropzoneSettings.acceptedFiles.includes(badFile.type))
        alert('Otillåten fil. Ladda upp en jpg, jpeg eller png bild.')
    }
  }

  handleOnImageLoaded = image => {
    var crop = {}

    if (image.width > image.height)
      crop = {  ...this.state.crop, x: 0, y: 0, width: image.height, height: image.height }
    else
      crop = {  ...this.state.crop, x: 0, y: 0, width: image.width, height: image.width };

    var percentageCropped = { width: crop.width / image.width * 100, height: crop.height / image.height * 100 }

    this.setState({ crop })
    this.handleOnCropComplete( crop, percentageCropped)
    return false;
  };

  handleOnCropChange = (crop) =>
    this.setState({ crop })

  handleOnCropComplete = (pixelCrop, percentCrop) =>
    image64toCanvasRef(this.imageCanvasRef.current, this.state.imgSrc, pixelCrop, percentCrop)

  handleClearToDefault = (e) => {
    if (e) e.preventDefault();
    const canvas = this.imageCanvasRef.current
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    if (e) this.setState({ ...INITIAL_STATE, showModal: true })
    else this.setState({ ...INITIAL_STATE })
  }

  onSubmit = (e) => {
    e.preventDefault();
    
    if (this.state.imgSrc) {
      const fileName = `profilepic.${this.state.imgSrcExt}`
      const imageData = this.imageCanvasRef.current.toDataURL(`image/.${this.state.imgSrcExt}`)

      const croppedFile = base64StringtoFile(imageData, fileName)

      this.props.firebase.setProfilePicture(croppedFile)
        .then(() => {
          this.handleClearToDefault(null);
        })
    }
  }

  onDelete = (e) => {
    e.preventDefault();

    this.props.firebase.deleteProfilePicture()
    this.setState({ ...INITIAL_STATE })
  }

  componentDidUpdate(prevProps) {
    if (this.props.editable && this.props.editable !== prevProps.editable)
      this.setState({ editableProfile: this.props.editable })
  }

  render() {
    return (
      <div className="ImageUpload">
        <Card className="profilePicCard" onClick={() => this.setState({ showModal: this.state.editableProfile ? true : false })}>
          {this.props.picture 
            ? <Image as="card-img" roundedCircle width={isMobile ? 80 : 180} height={isMobile ? 80 : 180} src={this.props.picture} alt="ProfilePic placeholder"/>
            : <Image as="card-img" roundedCircle width={isMobile ? 80 : 180} height={isMobile ? 80 : 180} src={ProfilePlaceholder} alt="Generic placeholder"/>
          }
          {this.state.editableProfile &&  
            <Card.ImgOverlay className="d-flex justify-content-center">
              <FontAwesomeIcon className="align-self-center" size="3x" icon={faPencilAlt}/>
            </Card.ImgOverlay> 
          }
        </Card>

        <Modal size="lg" show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
          <Modal.Header closeButton>
            Uppdatera profilbild
          </Modal.Header>
          <Modal.Body>
            <Container fluid style={{ padding: '0px' }}>
              <Row>
                <Col md={6} xs={12}>
                  {this.state.imgSrc
                    ? <ReactCrop 
                        src={this.state.imgSrc}
                        crop={this.state.crop}
                        minWidth={50}
                        minHeight={50}
                        circularCrop={true}
                        keepSelection={true}
                        onImageLoaded={this.handleOnImageLoaded}
                        onChange={this.handleOnCropChange}
                        onComplete={this.handleOnCropComplete}
                        style={{ width: '100%' }}
                        imageStyle={{ width: '100%' }}/>
                    : <Card className="dropzoneCard justify-content-center"
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#f7f7f7',
                          border: '5px dotted #777',
                          marginBottom: isMobile ? '20px' : 'unset'
                        }}>
                        <Dropzone 
                          onDropAccepted={files => this.handleOnDrop(files)}
                          onDropRejected={badFiles => this.handleOnDrop(null, badFiles)}
                          accept={this.dropzoneSettings.acceptedFiles}
                          multiple={false}
                          maxSize={this.dropzoneSettings.maxSize}>
                          {({getRootProps, getInputProps}) => (
                            <div className="container" style={{ padding: '0px' }}>
                              <div
                                {...getRootProps({
                                  className: 'dropzone',
                                  style: { height: '100%', padding: '10px 0px 5px 0px' }
                                })}
                              >
                                <input {...getInputProps()} />
                                
                                {isMobile 
                                  ? <div style={{ margin: '20px' }}>
                                      <FontAwesomeIcon className="align-self-center" size="5x" icon={faImage} style={{ paddingBottom: '5px' }}/>
                                      <p>Klicka för att ladda upp eller ta en ny bild</p>
                                    </div>
                                  : <div style={{ margin: '20px' }}>
                                      <FontAwesomeIcon className="align-self-center" size="5x" icon={faCloudUploadAlt} style={{ paddingBottom: '5px' }}/>
                                      <p>Drag hit en bild eller klicka för att välja en ny bild</p>
                                    </div>
                                }
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Card>
                  }
                </Col>
                <Col className="buttonCol d-flex justify-content-center flex-column" style={{}}>
                  <canvas ref={this.imageCanvasRef} style={{ display: 'none' }}></canvas>

                  {this.state.imgSrc
                    ? <div>
                        <Button onClick={this.onSubmit} variant="success" style={{ fontSize: '15px', marginBottom: '5px' }} block>Spara bild</Button>
                        <Button onClick={this.handleClearToDefault} style={{ fontSize: '15px', marginBottom: '5px' }} block>Börja om</Button>
                      </div>
                    : <div>
                        <Button onClick={this.onSubmit} variant="success" style={{ fontSize: '15px', marginBottom: '5px' }} block disabled>Spara bild</Button>
                        <Button onClick={this.handleClearToDefault} style={{ fontSize: '15px', marginBottom: '5px' }} block disabled>Börja om</Button>
                      </div>
                  }
                  <hr style={{ width: '100%', borderStyle: 'solid', borderColor: '#333' }}/>
                  <Button onClick={this.onDelete} variant="danger" style={{ fontSize: '15px', marginTop: '5px' }} block>
                    <FontAwesomeIcon icon={faTrashAlt}/>&emsp;Ta bort nuvarande bild
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withFirebase(ImageUpload);