import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import RingLoader from 'react-spinners/RingLoader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  email: '',
  password: '',
  passwordMasking: true,
  loading: false,
  loadingFbOAuth: false,
  error: null,
};

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = e => {
    this.setState({ loading: true })

    this.props.firebase
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => {
        if (this.props.from) this.props.history.replace(this.props.from);
        else this.props.history.replace(ROUTES.LANDING);
      })
      .catch(error => {
        this.setState({ loading: false, error });
      });

    e.preventDefault();
  };

  facebookSubmit = e => {
    this.setState({ loadingFbOAuth: true })

    this.props.firebase
      .signInWithFacebook()
      .then(() => {
        if (this.props.from) this.props.history.replace(this.props.from);
        else this.props.history.replace(ROUTES.LANDING);
      })
      .catch(error => {
        this.setState({ loadingFbOAuth: false, error });
      });

    e.preventDefault();
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div>
        <h3>Logga in</h3>
        {!this.state.loading && !this.state.loadingFbOAuth
          ? <Form onSubmit={this.onSubmit} autoComplete="off">
              <Form.Control name="email" value={this.state.email} onChange={this.onChange}
                type="text" placeholder="Mailadress" />

              <div className="passwordMasking">
                <FontAwesomeIcon onClick={() => this.setState(prevState => ({ passwordMasking: !prevState.passwordMasking }))} 
                                 icon={this.state.passwordMasking ? faEye : faEyeSlash} fixedWidth />
              </div>
              <Form.Control name="password" value={this.state.passwordOne} onChange={this.onChange}
                type={this.state.passwordMasking ? "password" : "passwordUnmasked"} placeholder="Lösenord" />

              <Button type="submit">Logga in</Button>

              <Button className="btn-facebook" onClick={(e) => this.facebookSubmit(e)}>
                <FontAwesomeIcon size="1x" icon={faFacebookF} fixedWidth />
                Logga in med Facebook
              </Button>

              {this.state.error && <p className="error">{this.state.error.message}</p>}

              <Button variant="success" onClick={(e) => this.props.createNewUser(e, true)}>Skapa ett konto</Button>
            </Form>
          : <div className="loadingIndication">
              <RingLoader
                css={{ flex: 1, margin: '50px 0px 100px', alignSelf: "center" }}
                loading={this.state.loading || this.state.loadingFbOAuth}
              />
              <p>{this.state.loading && 'Loggar in mot Spelarpoolens server'}</p>
              <p>{this.state.loadingFbOAuth && 'Väntar på svar från Facebook'}</p>
            </div>
        }

        {!this.state.loading && !this.state.loadingFbOAuth &&
          <a href='/resetPassword' onClick={(e) => this.props.forgotPassword(e, true)}>Glömt lösenordet?</a>
        }
      </div>
    );
  }
}

export default compose(withRouter,withFirebase)(SignIn);