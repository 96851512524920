import React, { Component } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './ConfirmAlert.scss';

class ConfirmAlert extends Component {
  constructor(props) {
    super(props)

    this.state = {
      validated: false
    }
  }

  validationConfirm = (e) => {
    if (this.props.confirmation === e.target.value)
      this.setState({ validated: true })
    else if (this.state.validated === true)
      this.setState({ validated: false })
  }

  render() {
    return (
      <Modal className="ConfirmAlert" show={this.props.showMe} onHide={this.props.onHide} onExiting={this.props.onExiting} onClick={e => e.stopPropagation()}>
        <Modal.Header className={this.props.type === 'delete' ? 'deleteHeader' : 'confirmHeader'}>
          <Modal.Title id="example-modal-sizes-title-lg">
            {this.props.title}
          </Modal.Title>
          <p className="secondTitle">{this.props.secondTitle}</p>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              {this.props.description}
            </Row>
            {this.props.confirmation && 
              <Row>
                <Form.Control name="confirmation" onChange={this.validationConfirm} placeholder={this.props.confirmation} autoComplete="off"/>
              </Row>
            }
            <Row className="buttonBar">
              <Button variant="light" onClick={() => this.props.onHide()} block>
                Avbryt
              </Button>
              {this.props.type === 'confirm' && 
                <Button onClick={() => this.props.action()} block>
                  Fortsätt
                </Button>
              }
              {this.props.type === 'delete' && 
                <Button onClick={() => this.props.action()}
                        disabled={this.props.confirmation && !this.state.validated}
                        variant="danger" block>
                  Ta bort
                </Button>
              }
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ConfirmAlert
