import React, { Component } from 'react';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { AuthUserContext } from '../Auth';

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faAward, faTableTennis } from '@fortawesome/free-solid-svg-icons';
import { faCalendarPlus, faUserCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import './Navigation.scss';

import * as ROUTES from '../../constants/routes';

class Navigation extends Component {
	activeRoute = (ROUTELIST) => {
		var path = this.props.location.pathname;

		if (!!matchPath(path, ROUTES.LOGIN) && matchPath(path, ROUTES.LOGIN).isExact)
			path = this.props.location.state && this.props.location.state.from ? this.props.location.state.from : path;

		var flag = 'notActive'
		ROUTELIST.map(ROUTE => {
			if (!!matchPath(path, ROUTE) && matchPath(path, ROUTE).isExact)
				return flag = 'active';
			return flag;
		})
		return flag;
	}

	render() {
		return(
			<Col className="navBrowser" xl={2} lg={3} md={3} xs={4}>
				<Navbar id="navigation" bg=" " variant="dark">
					<Link to={ROUTES.LANDING}>
						<Navbar.Brand id="title">
							<img src="/images/Spelarpoolen.svg" width="100%" alt="Spelarpoolen logo" />
						</Navbar.Brand>
					</Link>
					<Col className="justiy-content-center flex-column d-flex">
						<Nav className="mt-auto">
							<Link to={ROUTES.LANDING} className={`nav-link ${this.activeRoute([ROUTES.LANDING, ROUTES.MATCH, ROUTES.MATCHID])}`}>
								<FontAwesomeIcon size="2x" icon={faSearch} fixedWidth/>&emsp;Hitta match
							</Link>
							<Link to={ROUTES.CREATE} className={`nav-link ${this.activeRoute([ROUTES.CREATE, ROUTES.PREVIEW, ROUTES.NEW, ROUTES.REQUESTS, ROUTES.RESULTS])}`}>
								<FontAwesomeIcon size="2x" icon={faCalendarPlus} fixedWidth/>&emsp;Skapa match
							</Link>
							<Link to={ROUTES.LEADERBOARD} className={`nav-link ${this.activeRoute([ROUTES.LEADERBOARD])}`}>
								<FontAwesomeIcon size="2x" icon={faAward} fixedWidth/>&emsp;Topplistan
							</Link>
							<Link to={ROUTES.MYMATCHES} className={`nav-link ${this.activeRoute([ROUTES.MYMATCHES, ROUTES.MYMATCHESID])}`}>
								<FontAwesomeIcon size="2x" icon={faTableTennis} fixedWidth/>&emsp;Mina matcher
							</Link>
						</Nav>
						<Nav className="mt-auto">
							<AuthUserContext.Consumer>
								{authUser => authUser 
									? <Link to={`${ROUTES.PROFILE}/${authUser.uid}`} className={`nav-link ${this.activeRoute([ROUTES.PROFILEID, ROUTES.SPORTSELECT])}`}>
											<FontAwesomeIcon size="2x" icon={faUserCircle} fixedWidth/>&emsp;Min profil
										</Link>
									: <Link to={ROUTES.ME} className={`nav-link ${this.activeRoute([ROUTES.ME])}`}>
											<FontAwesomeIcon size="2x" icon={faUserCircle} fixedWidth/>&emsp;Min profil
										</Link>
								}
							</AuthUserContext.Consumer>
							<Link to={ROUTES.HELP} className={`nav-link ${this.activeRoute([ROUTES.HELP])}`}>
								<FontAwesomeIcon size="2x" icon={faQuestionCircle} fixedWidth/>&emsp;Hjälp
							</Link>
						</Nav>
					</Col>
				</Navbar>
			</Col>
		)}
}

export default withRouter(Navigation);