export const CITYLIST = [
	"",
	"Alingsås",
	"Arboga",
	"Arvika",
	"Askersund",
	"Avesta",
	"Boden",
	"Bollnäs",
	"Borgholm",
	"Borlänge",
	"Borås",
	"Djursholm",
	"Eksjö",
	"Enköping",
	"Eskilstuna",
	"Eslöv",
	"Fagersta",
	"Falkenberg",
	"Falköping",
	"Falun",
	"Filipstad",
	"Flen",
	"Gränna",
	"Gävle",
	"Göteborg",
	"Hagfors",
	"Halmstad",
	"Haparanda",
	"Hedemora",
	"Helsingborg",
	"Hjo",
	"Hudiksvall",
	"Huskvarna",
	"Härnösand",
	"Hässleholm",
	"Höganäs",
	"Jönköping",
	"Kalmar",
	"Karlshamn",
	"Karlskoga",
	"Karlskrona",
	"Karlstad",
	"Katrineholm",
	"Kiruna",
	"Kramfors",
	"Kristianstad",
	"Kristinehamn",
	"Kumla",
	"Kungsbacka",
	"Kungälv",
	"Köping",
	"Laholm",
	"Landskrona",
	"Lidköping",
	"Lindesberg",
	"Linköping",
	"Ljungby",
	"Ludvika",
	"Luleå",
	"Lund",
	"Lycksele",
	"Lysekil",
	"Malmö",
	"Mariefred",
	"Mariestad",
	"Marstrand",
	"Mjölby",
	"Motala",
	"Mölndal",
	"Nora",
	"Norrköping",
	"Norrtälje",
	"Nybro",
	"Nyköping",
	"Nynäshamn",
	"Nässjö",
	"Oskarshamn",
	"Oxelösund",
	"Piteå",
	"Ronneby",
	"Sala",
	"Sandviken",
	"Sigtuna",
	"Simrishamn",
	"Skanör-Falsterbo",
	"Skara",
	"Skellefteå",
	"Skänninge",
	"Skövde",
	"Sollefteå",
	"Stockholm",
	"Strängnäs",
	"Strömstad",
	"Sundsvall",
	"Säffle",
	"Säter",
	"Sävsjö",
	"Söderhamn",
	"Söderköping",
	"Södertälje",
	"Sölvesborg",
	"Tidaholm",
	"Torshälla",
	"Tranås",
	"Trelleborg",
	"Trollhättan",
	"Trosa",
	"Uddevalla",
	"Ulricehamn",
	"Umeå",
	"Uppsala",
	"Vadstena",
	"Varberg",
	"Vaxholm",
	"Vetlanda",
	"Vimmerby",
	"Visby",
	"Vänersborg",
	"Värnamo",
	"Västervik",
	"Västerås",
	"Växjö",
	"Ystad",
	"Åmål",
	"Ängelholm",
	"Örebro",
	"Öregrund",
	"Örnsköldsvik",
	"Östersund",
	"Östhammar",
]