import React, { Component } from 'react';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet-async';

import { withRouter, Link } from 'react-router-dom';
import { withFirebase } from '../components/Firebase';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';

import Header from '../components/Header/Header';
import ProfilePlaceholder from '../constants/ProfilePlaceholder.png';

import '../styles/LeaderboardPage.scss';

import * as SPORTS from '../constants/sports';
import * as ROUTES from '../constants/routes';

class LeaderboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sport: '',
      loading: true
    }
  }

  addProfileListener = async () => {
    var currentSport = '';

    if (this.props.firebase.auth.currentUser || JSON.parse(localStorage.getItem("authUser")))
    this.profileListener = this.props.firebase.getProfileListener(this.props.firebase.auth.currentUser ? this.props.firebase.auth.currentUser.uid : JSON.parse(localStorage.getItem("authUser")).uid)
      .onSnapshot(snapshot => {
        if (this.mounted) {
          var profile = snapshot.data();
          profile.uid = this.props.firebase.auth.currentUser.uid;

          if (profile.mySports && profile.mySports.length === 1) {
            this.setState({ profile, sport: profile.mySports[0] })
            currentSport = profile.mySports[0];
          }
          else if (profile.rankings && profile.rankings.length > 0) {
            var highestRankSport = Object.keys(profile.rankings).reduce((a, b) => profile.rankings[a] > profile.rankings[b] ? a : b);
            this.setState({ profile, sport: highestRankSport })
            currentSport = highestRankSport;
          }
          else {
            this.setState({ profile, sport: SPORTS.SPORTLIST[0] })
            currentSport = SPORTS.SPORTLIST[0];
          }

          this.props.firebase.getLeaderboardForSport(currentSport ? currentSport : SPORTS.SPORTLIST[0])
            .then(response => {
              if (this.mounted)
                this.setState({ sport: currentSport, leaderboard: response, loading: false })
          })
        }
      })
    else
      this.props.firebase.getLeaderboardForSport(SPORTS.SPORTLIST[0])
        .then(response => {
          if (this.mounted)
            this.setState(prevState => ({ sport: prevState.sport ? prevState.sport : SPORTS.SPORTLIST[0], leaderboard: response, loading: false  }))
      })
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value, loading: true });

    if (e.target.value)
      this.props.firebase.getLeaderboardForSport(e.target.value)
        .then(response => {
          if (this.mounted)
            this.setState({ leaderboard: response, loading: false })
      })
    else
      this.setState({ leaderboard: null, loading: false })
  };

  componentDidMount() {
    this.mounted = true;
    this.addProfileListener();
  }

  componentWillUnmount() {
    if (this.profileListener) this.profileListener();
    this.mounted = false;
  }

  render() {
    return (
      <div className="LeaderboardPage">
        <Helmet>
          <title>Spelarpoolen | Topplistan</title>
          <meta name="title" content="Ranking och topplista i din sport."/>
          <meta name="description" content={`Lägg upp era resultat från träningsmatcher och tävlingsmatcher och få din egen rankingpoäng. Jämför med andra spelare och tävla om att komma högre i rankinglistan. Finns för ${SPORTS.SPORTLIST.map(sport => ' ' + SPORTS[sport]).toString()}`} />
        </Helmet>

        <Col className="leaderboardContent" xs={12}>
          <Header title="Topplistan" loading={this.state.loading} profile={this.state.profile} history={this.props.history} />
          
          <Form.Control className="sportSelect" name="sport" value={this.state.sport} onChange={this.onChange} 
                      as="select" style={{ backgroundImage: `url(/images/sports/${this.state.sport}.svg)` }}>
            <option value="" disabled>Välj en sport</option>
            {this.state.profile &&
              <optgroup label="Dina sporter">
                {this.state.profile.mySports.map((sport, k) => (
                  <option key={k} value={sport}>{SPORTS[sport].charAt(0).toUpperCase() + SPORTS[sport].slice(1)}</option>
                ))}
              </optgroup>
            }
            {this.state.profile &&
              <optgroup label="Övriga sporter">
              {SPORTS.SPORTLIST.map((sport, k) => {
                if (this.state.profile && !this.state.profile.mySports.includes(sport))
                  return <option key={k} value={sport}>{SPORTS[sport].charAt(0).toUpperCase() + SPORTS[sport].slice(1)}</option>
                return null
              })}
              </optgroup>
            }
            {!this.state.profile && SPORTS.SPORTLIST.map((sport, k) => (
                <option key={k} value={sport}>{SPORTS[sport].charAt(0).toUpperCase() + SPORTS[sport].slice(1)}</option>
              ))
            }
          </Form.Control>

          {this.state.leaderboard 
            ? <div className="tableContainer">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Namn</th>
                    <th>Ranking</th>
                    <th>
                      {window.screen.width < 500 
                          ? 'Sida'
                          : 'Profilsida'
                        }
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.leaderboard.map((rankingObj, i) => (
                    <tr key={i}>
                      <td>
                        {i === 0 
                          ? <img src="/images/MedalGOLD.svg" alt="Gold medal" />
                          : i === 1 
                            ? <img src="/images/MedalSILVER.svg" alt="Silver medal" />
                            : i === 2
                              ? <img src="/images/MedalBRONZE.svg" alt="Bronze medal" />
                              : i+1
                        }
                      </td>
                      <td>
                        {rankingObj.profilePicture
                          ? <Image roundedCircle width={28} height={28} src={rankingObj.profilePicture} alt="Profile placeholder" />
                          : <Image roundedCircle width={28} height={28} src={ProfilePlaceholder} alt="Generic placeholder" />
                        }
                        {rankingObj.fullname}
                      </td>
                      <td>{Math.round(rankingObj.ranking)}</td>
                      <td><Link to={`${ROUTES.PROFILE}/${rankingObj.uid}`}>
                        {window.screen.width < 500 
                          ? '>>'
                          : 'Visa profil'
                        }
                      </Link></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            : <div className="leaderboardError">
              {this.state.sport &&
                !this.state.loading && <h5>Hittade inga resultat</h5>
              }
            </div>
          }
        </Col>
      </div>
    )
  }
}

export default compose(withFirebase, withRouter)(LeaderboardPage);
