export const LANDING = '/';
export const MATCH = '/match';
export const MATCHID = `${MATCH}/:matchId`;

export const WELCOME = '/welcome';

export const LOGIN = '/login';

export const CREATE = '/create';
export const PREVIEW = `${CREATE}/preview`;
export const NEW = `${CREATE}/new`;
export const REQUESTS = `${CREATE}/requests`;
export const REQUESTSID = `${CREATE}/requests/:matchId`;
export const RESULTS = `${CREATE}/results`;

export const LEADERBOARD = '/leaderboard';

export const MYMATCHES = '/matches';
export const MYMATCHESID = `${MYMATCHES}/:matchId`;

export const ME = '/me';
export const PROFILE = '/profile';
export const PROFILEID = `${PROFILE}/:profileId`;
export const SPORTSELECT = `${PROFILE}/:profileId/sportselection`;
export const SPORTSELECTAPPEND = `/sportselection`;
export const PASSWORDUPDATE = `${PROFILE}/:profileId/updatepassword`;
export const PASSWORDAPPEND = `/updatepassword`;

export const PRIVACY = '/privacypolicy';
export const TERMS = '/termsofservice';

export const HELP = '/help';
