import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet-async';
import { isBrowser, isMobile } from 'react-device-detect';
import { Transition } from 'react-spring/renderprops';

import { withFirebase } from '../components/Firebase';
import { withAuthorization } from '../components/Auth';

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Media from 'react-bootstrap/Media';
import Button from 'react-bootstrap/Button';

import Header from '../components/Header/Header';
import Report from '../components/Report/Report';
import ImageUpload from '../components/ImageUpload/ImageUpload';
import PasswordUpdate from '../components/PasswordUpdate/PasswordUpdate';
import SportSelection from '../components/SportSelection/SportSelection';
import EditableUserInfo from '../components/EditableUserInfo/EditableUserInfo';

import * as SPORTS from '../constants/sports';
import * as ROUTES from '../constants/routes';
import * as CITIES from '../constants/cities';

import '../styles/AccountPage.scss';

class AccountPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editable: false,
      editSports: false,
      editPassword: false,
      sidebarWasShowing: false,
      sidebarWillShow: false,
      scrollStart: true,
      scrollEnd: false,
      loading: true,
      loadingPrivate: true
    }
  }

  addProfileListener() {
    var userId = this.props.match.params.profileId;

    this.profileListener = this.props.firebase.getProfileListener(userId)
      .onSnapshot(snapshot => {
        var profile = snapshot.data();
        if (profile) {
          profile.uid = userId;
          this.setState({ profile, loading: false })
        }
      })

    if (this.props.firebase.auth.currentUser.uid === userId)
    this.privateListener = this.props.firebase.getPrivateListener(userId)
      .onSnapshot(snapshot => {
        var privateProfile = snapshot.data();

        this.setState({ privateProfile, loadingPrivate: false })
      })
  }

  handleScroll = e => {
    if(isBrowser) {
      let element = e.target

      if (element.scrollLeft === 0)
        this.setState({ scrollStart: true })
      else {
        if (this.state.scrollStart === true)
          this.setState({ scrollStart: false })

        if (element.scrollWidth - element.scrollLeft === element.clientWidth)
          this.setState({ scrollEnd: true })
        else if (this.state.scrollEnd === true)
          this.setState({ scrollEnd: false })
      }
    }
  }

  checkIfProfileOwner() {
    if (this.props.firebase.auth.currentUser.uid === this.props.match.params.profileId)
      this.setState({ editable: true })
    else
      this.setState({ editable: false })
  }

  showSportSelect = (bool = false) => {
    let path = this.props.history.location.pathname;

    if (isMobile) {
      if (bool && !!!matchPath(path, ROUTES.SPORTSELECT)) this.props.history.push(`${ROUTES.PROFILE}/${this.props.match.params.profileId}${ROUTES.SPORTSELECTAPPEND}`)
      else this.props.history.goBack()
    }
    else {
      if (bool && !!!matchPath(path, ROUTES.SPORTSELECT)) this.props.history.replace(`${ROUTES.PROFILE}/${this.props.match.params.profileId}${ROUTES.SPORTSELECTAPPEND}`)
      else this.props.history.replace(`${ROUTES.PROFILE}/${this.props.match.params.profileId}`)
    }
    
    this.setState({ editSports: bool, editPassword: false, sidebarWillShow: bool })
  }

  showPasswordChange = (bool = false) => {
    let path = this.props.history.location.pathname;

    if (isMobile) {
      if (bool && !!!matchPath(path, ROUTES.PASSWORDUPDATE)) this.props.history.push(`${ROUTES.PROFILE}/${this.props.match.params.profileId}${ROUTES.PASSWORDAPPEND}`)
      else this.props.history.goBack()
    }
    else {
      if (bool && !!!matchPath(path, ROUTES.PASSWORDUPDATE)) this.props.history.replace(`${ROUTES.PROFILE}/${this.props.match.params.profileId}${ROUTES.PASSWORDAPPEND}`)
      else this.props.history.replace(`${ROUTES.PROFILE}/${this.props.match.params.profileId}`)
    }

    this.setState({ editPassword: bool, editSports: false, sidebarWillShow: bool })
  }

  componentDidMount() {
    this.addProfileListener();
    this.checkIfProfileOwner();

    if (this.props.history.action === 'POP' && !!matchPath(this.props.history.location.pathname, ROUTES.PASSWORDUPDATE))
      this.props.history.replace(`${ROUTES.PROFILE}/${this.props.match.params.profileId}`)
  }

  componentWillUnmount() {
    this.profileListener();
    if (this.privateListener) this.privateListener();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.profileId !== this.props.match.params.profileId) {
      this.profileListener();
      this.addProfileListener();
      this.checkIfProfileOwner();
    }

    if (prevState.sidebarWasShowing === true && !(prevState.editSports || prevState.editPassword))
      this.setState({ sidebarWasShowing: false })
    else if (prevState.sidebarWasShowing === false && (prevState.editSports || prevState.editPassword))
      this.setState({ sidebarWasShowing: true })

    if (prevState.editSports === false && !!matchPath(this.props.location.pathname, ROUTES.SPORTSELECT))
      this.setState({ editSports: true, editPassword: false, sidebarWillShow: true })
    else if (!prevState.editPassword && !!matchPath(this.props.location.pathname, ROUTES.PASSWORDUPDATE))
      this.setState({ editSports: false, editPassword: true, sidebarWillShow: true })
    else if ((prevState.editPassword && !!!matchPath(this.props.location.pathname, ROUTES.PASSWORDUPDATE)) || 
             (prevState.editSports && !!!matchPath(this.props.location.pathname, ROUTES.SPORTSELECT)))
      this.setState({ editSports: false, editPassword: false, sidebarWillShow: false })
  }

  render() {
    var rankingsList = [];
    if (this.state.profile && this.state.profile.rankings) {
      rankingsList = Object.keys(this.state.profile.rankings).sort((a, b) => this.state.profile.rankings[b] - this.state.profile.rankings[a])
    }

    return (
      <div className="AccountPage">
        <Helmet>
          {this.state.profile ? <title>Spelarpoolen | {this.state.profile.fullname}</title> : <title>Spelarpoolen | Profil</title>}
          <meta name="title" content="Profil med ranking i sport"/>
          <meta name="description" content="Lägg upp din användare med din/dina sporter och skapa matcher att spela. Höj din ranking och få ett tävlingsmoment i varje match." />
        </Helmet>

        <Col className={`profileContent ${isBrowser ? 'browserMode' : 'mobileMode'}`} md={(this.state.editSports || this.state.editPassword) && isBrowser ? 8 : 12} sm={12}>
          <Header title={this.state.editable ? 'Min profil' : `${this.state.loading ? 'Laddar profil' : `${this.props.firebase.nameToGenitive(this.state.profile.firstname)} profil`}`}
            loading={this.state.loading} profile={this.state.profile} history={this.props.history} profilePage={true} />

          <div className={`profileContentInner ${isBrowser ? 'browserMode' : 'mobileMode'}`}>
            <Media>
              <Media.Body>
                <h1>{this.state.loading ? 'Loading profile...' : this.state.profile.fullname}</h1>
                {this.state.loading 
                  ? <p>Ålder: <span className="line skeletonLoading" style={{ width: '40px' }}/></p>
                  : <EditableUserInfo label="Ålder" altLabel="Födelsedatum" type="date" field="birthdate"
                    editable={this.state.editable} fieldData={this.state.profile.birthdate} />
                }
                {this.state.loading 
                  ? <p>Kön: <span className="line skeletonLoading" style={{ width: '50px' }}/></p>
                  : <EditableUserInfo label="Kön" type="select" placeholder="Ange kön" field="gender"
                    options={[{ value: 'male', label: 'Man' }, { value: 'female', label: 'Kvinna' }, { value: 'other', label: 'Annat/vill ej ange' }]}
                    editable={this.state.editable} fieldData={this.state.profile.gender} />
                }
                {this.state.loading 
                  ? <p>Bostadsort: <span className="line skeletonLoading" style={{ width: '90px' }}/></p>
                  : <EditableUserInfo label="Bostadsort" type="select" placeholder="Ange stad" field="city"
                  options={CITIES.CITYLIST.slice(1).map(city => { return { value: city }})}
                  editable={this.state.editable} fieldData={this.state.profile.city} />
                }
                {this.state.editable &&
                  <div>
                    {this.state.loadingPrivate
                      ? <p>Email: <span className="line skeletonLoading" style={{ width: '125px' }}/></p>
                      : <EditableUserInfo label="Email" type="text" field="email"
                      editable={this.state.editable} fieldData={this.state.privateProfile.email} secret={true} />
                    }
                    {this.state.loadingPrivate 
                      ? <p>Telefon: <span className="line skeletonLoading" style={{ width: '112px' }}/></p>
                      : <EditableUserInfo label="Telefon" type="text" field="telephone"
                      editable={this.state.editable} fieldData={this.state.privateProfile.telephone} secret={true} />
                    }
                  </div>
                }
              </Media.Body>

              {!this.state.loading && <ImageUpload editable={this.state.editable} picture={this.state.profile.profilePicture} />}
            </Media>


            {this.state.editable && this.props.firebase.auth.currentUser && this.props.firebase.auth.currentUser.providerData.length < 2 &&
              <Col className="profileTools pad" md={6} xs={12}>
                <Button onClick={() => this.showPasswordChange(true)} block>Ändra mitt lösenord</Button>
              </Col>
            }

            {!this.state.loading && 
              <h4> {this.state.editable 
                ? 'Dina sporter'
                : `${this.props.firebase.nameToGenitive(this.state.profile.firstname)} sporter`
                }
              </h4>
            }
            
            {isBrowser && !this.state.scrollStart && <div className="shader" />}
            {isBrowser && !this.state.scrollEnd && <div className="shader inverse" />}
            <div className="rankings" onScroll={this.handleScroll}>
              {!this.state.loading && this.state.profile.rankings && rankingsList.map((sport, k) => (
                <Card key={k} className={`rankingCard ${this.state.profile.rankings[sport] >= 1500 ? 'gold' : ''}${this.state.profile.rankings[sport] < 1500 && this.state.profile.rankings[sport] >= 1200 ? 'silver' : ''}${this.state.profile.rankings[sport] < 1200 && this.state.profile.rankings[sport] >= 1000 ? 'bronze' : ''}`}>
                  {this.state.profile.rankings[sport] >= 1500 && <object type="image/svg+xml" data="/images/MedalGOLD.svg">
                    <param name="sporturl" value={`sports/${sport}.svg`} />
                  </object>}
                  {this.state.profile.rankings[sport] < 1500 && this.state.profile.rankings[sport] >= 1200 && <object type="image/svg+xml" data="/images/MedalSILVER.svg">
                    <param name="sporturl" value={`sports/${sport}.svg`} />
                  </object>}
                  {this.state.profile.rankings[sport] < 1200 && <object type="image/svg+xml" data="/images/MedalBRONZE.svg">
                    <param name="sporturl" value={`sports/${sport}.svg`} />
                  </object>}
                  <p>{SPORTS[sport]}: {Math.round(this.state.profile.rankings[sport])}</p>
                </Card>
              ))}
              {!this.state.loading && this.state.profile.rankings && rankingsList.length === 0 &&
                <Card className="rankingCard">
                  <object type="image/svg+xml" data="/images/MedalGOLD.svg" aria-label="Ranked medal"/>
                  <p className="noRank">Ingen ranking ännu</p>
                </Card>
              }
              {this.state.loading &&
                <Card className="rankingCard">
                 <object type="image/svg+xml" data="/images/MedalGOLD.svg" aria-label="Ranked medal"/>
                 <p className="noRank">Laddar ranking...</p>
                </Card>
              }
            </div>

            {!this.state.loading && 
              <Col className="profileTools" md={6} xs={12}>
              {!this.state.editable
                ? this.state.profile && <Report key="reportLoad" profile={this.state.profile} user={this.state.profile} />
                : <Button variant="success" onClick={() => this.showSportSelect(true)} block>Ändra mina sporter</Button>
              }
              </Col>
            }
            
          </div>
        </Col>

        {!this.state.loading &&
          <Transition
            items={this.state.editSports}
            // initial={this.props.history.action === "POP" ? null : undefined}
            from={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                    opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWasShowing ? '0' : '1',
                    position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
            enter={{ transform: 'translate3d(0%, 0, 0)', opacity: '1', position: isBrowser && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
            leave={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                    opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWillShow ? '0' : '1',
                    position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWillShow ? 'relative' : 'absolute', right: 0 }}>
            {show => show &&
              (props => 
                <SportSelection profile={this.state.profile} saveChanges={this.showSportSelect} transition={props} /> 
            )}
          </Transition>
        }

        {!this.state.loading &&
          <Transition
            items={this.state.editPassword}
            // initial={this.props.history.action === "POP" ? null : undefined}
            from={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                    opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWasShowing ? '0' : '1',
                    position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
            enter={{ transform: 'translate3d(0%, 0, 0)', opacity: '1', position: isBrowser && !this.state.sidebarWasShowing ? 'relative' : 'absolute', right: 0 }}
            leave={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)',
                    opacity: isBrowser && window.innerWidth >= 768 && this.state.sidebarWillShow ? '0' : '1',
                    position: isBrowser && window.innerWidth >= 768 && !this.state.sidebarWillShow ? 'relative' : 'absolute', right: 0 }}>
            {show => show &&
              (props => 
                <PasswordUpdate hideMe={this.showPasswordChange} transition={props} /> 
            )}
          </Transition>
        }
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withRouter, withFirebase, withAuthorization(condition))(AccountPage);