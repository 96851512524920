import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Header from '../components/Header/Header';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faAward, faListOl } from '@fortawesome/free-solid-svg-icons';

import * as SPORTS from '../constants/sports';
import * as ROUTES from '../constants/routes';

import '../styles/FirstVisitPage.scss';

class FirstVisitPage extends Component {
  componentDidMount() {
    localStorage.setItem('firstVisit', 1)

    if (localStorage.getItem('authUser'))
      this.props.history.replace(this.props.location.state ? this.props.location.state.from : ROUTES.LANDING)
  }

  render() {
    return (
      <div className="firstVisitPage" style={{ textAlign: isMobile ? 'center' : 'left' }}>
        <Helmet>
          <title>Spelarpoolen | Välkommen</title>
          <meta name="title" content="Hitta spelare, motståndare och medspelare. Ranking och topplista."/>
          <meta name="description" content={`Skapa eller sök matcher och hitta med- och motståndare i din sport. Tävla med vårt rankingsystem, som ger rankingpoäng efter varje spelad match. Gå med och se hur högt du kan klättra i ranking! Vi har ${SPORTS.SPORTLIST.map(sport => ' ' + SPORTS[sport]).toString()}`} />
        </Helmet>

        {!isMobile &&
          <Header title="Välkommen till spelarpoolen" loading={true} />
        }

        {isMobile &&
          <img src="/images/Spelarpoolen.svg" width="50%" alt="Spelarpoolen" />
        }

        <p>
          <FontAwesomeIcon icon={faUserFriends} fixedWidth />
          Hitta nya spelare att spela med
        </p>

        <p>
          <FontAwesomeIcon icon={faAward} fixedWidth />
          Uppdaterad ranking efter varje match
        </p>

        <p>
          <FontAwesomeIcon icon={faListOl} fixedWidth />
          Rankingtabell i alla sporter
        </p>

        <Row className="prImages">
          <div className={isMobile ? "smartphone" : "smartphoneDesktop"}>
            <Image src="/images/pr/landingpage.png" alt="Förstasidan" />
          </div>
          {isMobile
            ? <div className="smartphone">
                <Image src="/images/pr/leaderboard.png" alt="Topplistan" />
              </div>
            : <div className="desktop">
                <Image src="/images/pr/leaderboardDesktop.png" alt="Topplistan" />
              </div>
          }
        </Row>

        <Row className="buttons">
          <Col md={6} xs={12}>
            <Button variant="primary" onClick={() => this.props.history.replace(ROUTES.LOGIN)} block>
              Skapa konto / Logga in
            </Button>
          </Col>
          <Col md={6} xs={12}>
            <Button variant="dark" onClick={() => this.props.history.replace(this.props.location.state ? this.props.location.state.from : ROUTES.LANDING)} block>
              Fortsätt som gäst
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(FirstVisitPage);