import React, { Component } from 'react';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet-async';
import { isBrowser, isMobile } from 'react-device-detect';
import { Transition } from 'react-spring/renderprops';

import { withFirebase } from '../components/Firebase';
import { withRouter } from 'react-router-dom';
import { withAuthorization } from '../components/Auth';

import Col from 'react-bootstrap/Col';

import Header from '../components/Header/Header';
import Match from '../components/Match/Match';
import MatchCard from '../components/MatchCard/MatchCard';
import MatchCardSkeleton from '../components/MatchCard/MatchCardSkeleton';

import '../styles/MyMatchesPage.scss';

class MyMatchesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      matches: [],
      matchId: '',
      loading: true
    }
  }

  addProfileListener = async () => {
    this.profileListener = this.props.firebase.getProfileListener(this.props.firebase.auth.currentUser.uid)
      .onSnapshot(snapshot => {
        if (this.mounted) {
          var profile = snapshot.data();
          profile.uid = this.props.firebase.auth.currentUser.uid;

          this.setState({ profile })
        }
      })
  }

  componentDidMount() {
    this.mounted = true;
    this.props.firebase.resetPagination();
    var pListener = this.addProfileListener()

    var updateMatches = this.props.firebase.getMatchesByParticipant()
      .then(response => {
        if (this.mounted)
          this.setState({ matches: response })
    })

    Promise.all([pListener, updateMatches]).then(() => {this.setState({ loading: false })})
  }

  componentWillUnmount() {
    this.profileListener();
    this.mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    var matchId = this.props.match.params.matchId;

    if (matchId !== prevState.matchId)
      this.setState({ matchId })
  }

  render() {
    return (
      <div className="MyMatchesPage">
        <Helmet>
          <title>Spelarpoolen | Mina matcher</title>
          <meta name="title" content="Spela fler matcher och oftare."/>
          <meta name="description" content="Sök till andras matcher och hitta nya spelare att spela mot. Spelarpoolens rankingsystem gör det lättare att finna spelare på rätt nivå." />
        </Helmet>

        <Col className="createMatchContent" md={this.state.matchId && isBrowser ? 8 : 12} sm={12}>
          <Header title="Mina matcher" desc="Matcher du blivit antagen till" 
                  loading={this.state.loading} profile={this.state.profile} history={this.props.history} />

          <div id="matchList" className={isBrowser ? 'browserMode' : 'mobileMode'}>
            {this.state.loading
              ? <div className="loadingIndication">
                  <MatchCardSkeleton/>
                  <MatchCardSkeleton style={{ opacity: 0.7 }}/>
                  <MatchCardSkeleton style={{ opacity: 0.4 }}/>
                  <MatchCardSkeleton style={{ opacity: 0.2 }}/>
                </div>
              : this.state.matches && this.state.matches.length > 0
                ? this.state.matches.map((match, k) => (
                    <MatchCard key={k} matchObj={match} selected={this.state.matchId === match.id} />
                  ))
                : <div className="noResults">
                    <br/>
                    <h4>Du är inte antagen till någon match just nu</h4>
                    <br/>
                    <p>Ansök till någon annans match och håll tummarna på att bli antagen</p>
                  </div>
            }
          </div>
        </Col>

        <Transition
          items={this.state.matchId}
          initial={this.props.history.action === "POP" ? null : undefined}
          from={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)' }}
          enter={{ transform: 'translate3d(0%, 0, 0)' }}
          leave={{ transform: isMobile || window.innerWidth < 768 ? 'translate3d(105%, 0, 0)' : 'translate3d(0, 0, 0)' }}>
          {show => show &&
            (props => 
              <Match profile={this.state.profile} transition={props} /> 
          )}
        </Transition>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withFirebase, withRouter, withAuthorization(condition))(MyMatchesPage);