import React, { Component } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import { isBrowser, isMobile, mobileModel } from "react-device-detect";

import Card from 'react-bootstrap/Card';
import Media from 'react-bootstrap/Media';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faUserFriends, faTrophy, faComment, faSmileBeam, faPencilAlt, faSearch, faUserPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import ConfirmAlert from '../ConfirmAlert/ConfirmAlert';

import * as ROUTES from '../../constants/routes';
import * as SPORTS from '../../constants/sports';

import './MatchCard.scss';

class MatchCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      match: this.props.matchObj,
      selected: this.props.selected,
      isCreator: this.props.firebase.auth.currentUser && this.props.matchObj.author.uid === this.props.firebase.auth.currentUser.uid,
      showToolbar: false,
      showConfirm: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected)
      this.setState({ selected: this.props.selected })
    if (this.props.matchObj !== prevProps.matchObj)
      this.setState({ match: this.props.matchObj })
    if (this.props.resultSubmitted !== prevProps.resultSubmitted)
      this.setState({ resultSubmitted: this.props.resultSubmitted })
  }

  reRoute = (e, path, state) => {
    if (e) e.stopPropagation()
    if (isMobile && [ROUTES.LANDING, ROUTES.MATCH, ROUTES.CREATE].includes(this.props.location.pathname)) {
      this.props.history.push(path, state)
      this.setState({ showToolbar: false })
    }
    else
      this.props.history.replace(path, state)
  }

  routeBack = () => {
    if (matchPath(this.props.location.pathname, ROUTES.CREATE) || matchPath(this.props.location.pathname, ROUTES.PREVIEW) || matchPath(this.props.location.pathname, ROUTES.NEW) || matchPath(this.props.location.pathname, ROUTES.REQUESTS))
      this.props.history.replace(ROUTES.CREATE)
    else if (matchPath(this.props.location.pathname, ROUTES.MATCHID))
      this.props.history.replace(ROUTES.LANDING)
    else
      this.props.history.replace(ROUTES.MYMATCHES)
  }

  removeMatch = matchId => {
    this.props.firebase.deleteMatch(matchId).then(() => {
      this.setState({ selected: false, deleted: true, showConfirm: false, showToolbar: false })
      this.props.deleteToParent(matchId)
    })
  }

  showModal = (e, matchObj) => {
    if (e) e.stopPropagation();
    if (isBrowser) this.props.history.replace(ROUTES.PREVIEW, matchObj)
    else this.setState({ showToolbar: false, selected: true })
    this.setState({ showConfirm: true })
  }

  render() {
    var match = this.state.match
    var requests = this.props.requests

    var rankings = [match.author.ranking]
    match.acceptedPlayers.forEach(player => {
      rankings.push(player.ranking)
    })
    let sumRankings = rankings.reduce((previous, current) => current += previous);
    let avgRanking = Math.round(sumRankings / rankings.length);

    return (
      <Card className={`${this.state.selected ? 'selectedMatchCard' : ''} 
                        ${this.state.deleted ? 'deleted' : ''} ${this.state.resultSubmitted ? 'submitted' : ''}`}
                        onClick={(e) => this.state.isCreator
          ? this.setState(prevState => ({ showToolbar: !prevState.showToolbar, selected: isMobile ? true : prevState.selected }))
          : [!!matchPath(this.props.history.location.pathname, ROUTES.MYMATCHES) || !!matchPath(this.props.history.location.pathname, ROUTES.MYMATCHESID)
            ? this.reRoute(e, `${ROUTES.MYMATCHES}/${match.id}`, match)
            : this.reRoute(e, `${ROUTES.MATCH}/${match.id}`, match)
          ]}>
        {!!!this.state.deleted && requests && requests.length > 0 &&
          <Badge pill variant="warning">!</Badge>
        }
        <Card.Header>
          <p>
            <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth />
            {match.location}
          </p>
          <p className={`dateHeader ${match.startTime.seconds * 1000 <= new Date().valueOf() ? 'playing' : ''}${match.endTime.seconds * 1000 <= new Date().valueOf() ? 'ended' : ''}`}>
            {`${match.startTimeStr} - ${match.endTimeStr}`}
          </p>
        </Card.Header>
        <Card.Body className="matchOverview" style={{ display: isBrowser && this.state.showToolbar ? 'none' : 'block' }}>
          <Media>
            <Media.Body>
              {match.ranked
                ? <p><FontAwesomeIcon icon={faTrophy} fixedWidth />Rankad match (Medelrank: {avgRanking})</p>
                : <p><FontAwesomeIcon icon={faSmileBeam} fixedWidth />Vänskapsmatch</p>
              }
              <p>
                <FontAwesomeIcon icon={faUserFriends} fixedWidth />
                {`${match.numPlayersRequested - match.acceptedPlayers.length} spelare söks`}
              </p>
              <p>
                <FontAwesomeIcon icon={faComment} fixedWidth />
                {match.description}
              </p>
            </Media.Body>
            {SPORTS.SPORTLIST.includes(match.sport)
              ? <img className="align-self-center" src={`/images/sports/${match.sport}.svg`} alt={match.sport} />
              : <img className="align-self-center" src={`/images/sports/UNKNOWN.svg`} alt={'Unknown sport'} />
            }
          </Media>
        </Card.Body>

        <Card.Body className="creatorTools" style={{ display: isBrowser && this.state.showToolbar ? 'block' : 'none' }}>
          <Media>
            <Media.Body>
              <p onClick={(e) => this.reRoute(e, ROUTES.PREVIEW, match)}>
                <FontAwesomeIcon size="1x" icon={faSearch} fixedWidth />
                Visa match
                </p>
              <p onClick={(e) => this.reRoute(e, ROUTES.REQUESTS, match, requests)}>
                <FontAwesomeIcon size="1x" icon={faUserPlus} fixedWidth />
                Nya ansökningar
                {requests && requests.length > 0 &&
                  <Badge pill variant="warning">!</Badge>
                }
                </p>
              <p onClick={(e) => match.startTime.seconds * 1000 <= new Date().valueOf() ? this.reRoute(e, ROUTES.RESULTS, match) : e.stopPropagation()}
                className={match.startTime.seconds * 1000 >= new Date().valueOf() ? 'disabled' : 'enabled'}>
                <FontAwesomeIcon size="1x" icon={faTrophy} fixedWidth />
                Rapportera resultat
              </p>
            </Media.Body>
            <Media.Body>
              <p style={{ marginBottom: '33px' }} onClick={(e) => this.reRoute(e, ROUTES.NEW, match)}>
                <FontAwesomeIcon size="1x" icon={faPencilAlt} fixedWidth />
                Redigera match
              </p>
              <p onClick={(e) => this.showModal(e, match)}>
                <FontAwesomeIcon size="1x" icon={faTrashAlt} fixedWidth />
                Radera match
              </p>
            </Media.Body>
          </Media>
        </Card.Body>


        <ConfirmAlert
          title='Vill du radera denna match?'
          secondTitle='OBS: En raderad match går ej att återskapa'
          type='delete'
          description={
            <div>
              <p> Du har markerat din {SPORTS[match.sport]}-match i {match.location}.
              För att ta bort matchen skriv in <b>{SPORTS[match.sport]}</b> i rutan nedan.</p>
            </div>
          }
          confirmation={SPORTS[match.sport]}
          action={() => this.removeMatch(match.id)}
          showMe={this.state.showConfirm}
          onHide={() => this.setState({ showConfirm: false })}
          onExiting={() => this.setState(prevState => ({ showToolbar: isMobile ? false : prevState.showToolbar, selected: isMobile ? false : prevState.selected }))}
        />

        
        <Modal className="mobileCreatorTools" size="lg" show={isMobile && this.state.showToolbar} onExiting={() => this.setState({ selected: this.state.showConfirm ? true : false })}>
          <div className="slider" />
          <Modal.Body className={`${mobileModel} w${window.screen.width}h${window.screen.height}`} onClick={e => e.stopPropagation()}>
            <p onClick={(e) => this.reRoute(e, ROUTES.PREVIEW, match)}>
              <FontAwesomeIcon size="1x" icon={faSearch} fixedWidth />
              Visa match
            </p>
            <p onClick={(e) => this.reRoute(e, ROUTES.REQUESTS, match, requests)}>
              <FontAwesomeIcon size="1x" icon={faUserPlus} fixedWidth />
              Nya ansökningar
              {requests && requests.length > 0 &&
                <Badge pill variant="warning">!</Badge>
              }
            </p>
            <p onClick={(e) => match.startTime.seconds * 1000 <= new Date().valueOf() && this.reRoute(e, ROUTES.RESULTS, match)}
              className={match.startTime.seconds * 1000 >= new Date().valueOf() ? 'disabled' : 'enabled'}>
              <FontAwesomeIcon size="1x" icon={faTrophy} fixedWidth />
              Rapportera resultat
            </p>
            <hr />
            <p onClick={(e) => this.reRoute(e, ROUTES.NEW, match)}>
              <FontAwesomeIcon size="1x" icon={faPencilAlt} fixedWidth />
              Redigera match
            </p>
            <p onClick={(e) => this.showModal(e, match)}>
              <FontAwesomeIcon size="1x" icon={faTrashAlt} fixedWidth />
              Radera match
            </p>
          </Modal.Body>
        </Modal>
      </Card>
    )
  }
}

export default compose(withFirebase, withRouter)(MatchCard);
